import {
  blackColor,
  hexToRgb,
  primaryColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";

const customSwitchStyle = (theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    height: 56,
    paddingTop: 16,
    marginBottom: 2,
    boxSizing: "content-box",
    width: "100%",
    justifyContent: "space-between",
  },
  checked: {
    color: primaryColor[0] + "!important",
  },
  switchContainer: {
    marginLeft: 10,
    width: 45,
    "& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: `${primaryColor[0]} !important`,
    },
  },
  switchBase: {
    color: primaryColor[0] + "!important",
    "&:hover": {
      backgroundColor:
        "rgba(" + hexToRgb(primaryColor[0]) + ", 0.14) !important",
    },
  },
  switchIcon: {
    boxShadow: "0 1px 3px 1px rgba(" + hexToRgb(blackColor) + ", 0.4)",
    color: whiteColor + "  !important",
    border: "1px solid rgba(" + hexToRgb(blackColor) + ", .54)",
  },
  switchBar: {
    width: "30px",
    height: "15px",
    backgroundColor: "rgb(" + hexToRgb("#aaa") + ")",
    borderRadius: "15px",
    opacity: "0.7!important",
  },
});

export default customSwitchStyle;
