import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";
import retailersForm from "views/Retailers/retailersForm";
import StorefrontIcon from '@material-ui/icons/Storefront';

// appear in breadcrumbs and page headers
const listPageName = "Retailers";
const createPageName = "Create Retailer";
const editPageName = "Edit Retailer";

// appears on alerts; example: Entity created successfully
const entityName = "Retailer";

const layout = "/admin";

const path = "retailers";

const formStructure = retailersForm;

const dataResource = api.retailers;

const resource = enums.Resource.RETAILERS;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    canBeHighlighted: true,
  },
  {
    title: "Primary Phone",
    dataIndex: "phonePrimary",
    canBeHighlighted: true,
  },
  {
    title: "Secondary Phone",
    dataIndex: "phoneSecondary",
    canBeHighlighted: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    canBeHighlighted: true,
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: StorefrontIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
