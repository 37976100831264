import {
  dangerColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";

export default (theme) => ({
  root: {
    paddingTop: 24,
  },
  container: {
    margin: "17px 0px",
  },
  uploadIcon: {
    color: "#DEDEDE",
    background: whiteColor,
    height: 100,
    width: 100,
  },
  deleteIcon: { color: dangerColor[0], cursor: "pointer" },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
});
