import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

import styles from "./customInputStyle.js";

const useStyles = makeStyles(styles);

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    formControlProps,
    labelText,
    id,
    labelProps,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    helperText,
    disabled,
    inputRef,
    variant,
    inputCustomClasses,

    isTableInput,
  } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: variant === "underlined" && error,
    [classes.underlineSuccess]: variant === "underlined" && success && !error,
    [classes.underline]: variant === "underlined",
    [classes.whiteUnderline]: variant === "underlined" && white,
    [classes.noUnderline]: variant === "outlined" || variant === "none",
  });
  const outlinedClasses = classNames({
    [classes.noUnderline]: variant === "outlined" && error,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      isTableInput ? classes.tableInputFormControl : classes.formControl
    );
  } else {
    formControlClasses = isTableInput
      ? classes.tableInputFormControl
      : classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRootSuccess]: success && !error,
  });

  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined,
    step: inputProps?.type === "number" ? "any" : undefined,
  };

  var disabledOverlayClasses = classNames({
    [classes.disabledOverlay]: true,
    [classes.disabledOverlayActive]: disabled && !isTableInput,
  });

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <div className={disabledOverlayClasses} />
      {labelText !== undefined ? (
        <InputLabel
          shrink={
            inputProps.type === "date" ||
            inputProps.type === "datetime-local" ||
            inputProps.type === "time" ||
            inputProps.type === "month" ||
            inputProps.shrink ||
            (inputProps.value !== undefined && inputProps.value !== null)
              ? true
              : undefined
          }
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Input
        disabled={disabled}
        classes={{
          input: inputClasses + " " + inputCustomClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses,
        }}
        id={id}
        inputRef={inputRef}
        {...inputProps}
        value={
          (inputProps.type === "date" ||
            inputProps.type === "datetime-local" ||
            inputProps.type === "month") &&
          inputProps.value === undefined
            ? ""
            : inputProps.value
        }
        inputProps={newInputProps}
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

CustomInput.defaultProps = {
  variant: "underlined",
};

CustomInput.propTypes = {
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  helperText: PropTypes.node,
  variant: PropTypes.oneOf(["outlined", "underlined", "none"]),
};
