const styles = (theme) => ({
  root: {},
  tableContainer: {
    backgroundColor: "white",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      paddingTop: 40,
    },
  },
  raisedTableContainer: {
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    border: "1px solid #d7d7d7",
  },
  editableTableContainer: {
    [theme.breakpoints.down("md")]: {
      paddingTop: 120,
    },
  },
  tableContainerNoData: {
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  title: {
    fontWeight: 500,
  },
  tableHeader: {
    position: "absolute",
    top: 14,
    padding: "0px 20px",
    display: "flex",
    zIndex: 10,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      width: "100%",
      alignItems: "center",
      top: -35,
    },
  },
  tableHeaderEditable: {
    [theme.breakpoints.down("md")]: {
      top: -110,
    },
  },
  tableHeaderNoData: {
    position: "relative",
    marginBottom: 20,
    [theme.breakpoints.down("md")]: {
      top: 10,
    },
  },
  noHeader: {
    marginBottom: 0,
  },
  submit: {
    marginLeft: 20,
    height: "fit-content",
    padding: "7px 40px",
    borderRadius: 5,
  },
  footer: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    margin: "10px 0",
    padding: "0px 20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
    },
  },
});

export default styles;
