import {
  blackColor,
  cardTitle,
  dangerColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react";
import { grayColor } from "assets/jss/material-dashboard-pro-react.js";
import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

const selectInputStyle = (color) => (theme) => ({
  ...customDropdownStyle(theme),
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  cardTitle,
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex",
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0],
    },
  },
  mrAuto: {
    marginRight: "auto",
  },
  mlAuto: {
    marginLeft: "auto",
  },
  selectedValueColor: {
    "& .MuiSelect-selectMenu": {
      color: color ? color : "#3C4858",
    },
  },
  tableSelectFormControl: {
    margin: "0 0 0 0",
    "& > div": {
      "&:before": {
        borderBottomWidth: "1px !important",
        borderBottomColor: grayColor[4] + "!important",
      },
      "&:after": {
        borderBottomColor: blackColor + "!important",
      },
    },
  },
  clearIcon: {
    marginRight: "26px",
    cursor: "pointer",
    color: "#555",
    fontSize: "25px",
    borderRadius: "50%",
    padding: "3px",
    //hover
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  }
});

export default selectInputStyle;
