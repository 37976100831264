import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import enums from "enums/index";
import { Html5QrcodeScanner } from "html5-qrcode";
import React from "react";
import { formatEnums } from "utils/index";
import { useScanningWarrantiesView } from "./ScanningWarranties.hook";
import styles from "./ScanningWarranties.styles";

//-------------------------------------------------------------------------------------

const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

const hasRearCamera = () => {
  return new Promise((resolve) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        resolve(videoDevices.length > 0);
      })
      .catch((error) => {
        console.error("Error enumerating devices:", error);
        resolve(false);
      });
  });
};

const useStyles = makeStyles(styles);

export default function ScanningWarranties({ path }) {
  const classes = useStyles();

  const {
    isOpen,
    orderItems,
    canActivate,
    hasPendingItems,
    activeScreen,
    isConfirmModalOpen,
    error,
    orderId,
    currentPendingItemIdx,
    activateWarrantiesLoading,
    isLinkingCompleted,
    setIsConfirmModalOpen,
    activateWarranties,
    onItemScan,
    onItemUnlink,
    onItemSkip,
    openModal,
    onClose,
    handleActivateWarrantiesSuccess,
  } = useScanningWarrantiesView({ path });

  React.useEffect(() => {
    if (!isOpen) return;

    hasRearCamera().then((hasCamera) => {
      const facingMode =
        isMobileDevice() && hasCamera ? { exact: "environment" } : "user";

      const scanner = new Html5QrcodeScanner("reader", {
        qrbox: { width: 250, height: 250 },
        fps: 5,
        videoConstraints: { facingMode },
      });

      const success = (result) => {
        scanner.clear();
        onItemScan(result);
      };

      scanner.render(success);
    });
  }, [isOpen]);

  const defaultScreen = (
    <>
      {orderItems?.length ? (
        <h3 className={classes.orderItemsTitle}>Order Items</h3>
      ) : null}

      {orderItems?.length
        ? orderItems.map((item, index) => {
            return (
              <div key={`${item.id}-${index}`} className={classes.section}>
                <CollapseContainer
                  name="basicInfo"
                  titleComponent={
                    <h3 className={classes.itemName}>{item.name}</h3>
                  }
                  startCollapsed={currentPendingItemIdx !== index}
                  ignoreLocalStorage
                >
                  <div className={classes.itemDetailsContainer}>
                    <InfoWithLabelGridItem label="Serial" info={item?.serial} />
                    <InfoWithLabelGridItem label="Price" info={item?.price} />
                  </div>

                  <div className={classes.itemDetailsContainer}>
                    <InfoWithLabelGridItem
                      label="Status"
                      info={formatEnums(item?.status)}
                    />
                    {item?.publicKey ? (
                      <InfoWithLabelGridItem
                        label="Warranty Key"
                        info={item?.publicKey}
                      />
                    ) : null}
                  </div>
                  <div className={classes.buttonsContainer}>
                    {item?.status === enums.OrderItemStatus.PENDING ? (
                      <Button
                        className={classes.button}
                        onClick={onItemSkip}
                        color="black"
                      >
                        Skip
                      </Button>
                    ) : null}

                    <Button
                      className={classes.button}
                      onClick={
                        item?.status !== enums.OrderItemStatus.LINKED
                          ? openModal(index)
                          : onItemUnlink(index)
                      }
                      color="success"
                    >
                      {item?.status !== enums.OrderItemStatus.LINKED
                        ? "Scan"
                        : "Unlink"}
                    </Button>
                  </div>
                </CollapseContainer>
              </div>
            );
          })
        : null}
      <div className={classes.orderItemsContainer}>
        {orderItems?.length ? (
          <Button
            className={classes.activateButton}
            onClick={
              hasPendingItems
                ? () => {
                    console.log("hasPendingItems");
                    setIsConfirmModalOpen(true);
                  }
                : activateWarranties
            }
            disabled={!canActivate}
            color="success"
          >
            Activate Warranties
          </Button>
        ) : null}

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            if (activeScreen === "success") return;
            setIsConfirmModalOpen(false);
          }}
          headerMessage="Are you sure?"
          successHeaderText="Activated successfully"
          confirmMessage="Some of the items are not linked with warranties, are you sure you want to continue?"
          errorMessage="An error has occurred"
          successMessage="Warranties have been activated successfully"
          confirmButtonText="Confirm"
          confirmButtonColor="success"
          cancelButtonText="Cancel"
          activeScreen={activeScreen}
          loading={activateWarrantiesLoading}
          onConfirm={activateWarranties}
          onSuccess={handleActivateWarrantiesSuccess}
        />
      </div>
    </>
  );

  const successScreen = (
    <div className={classes.successContainer}>
      <img
        src={require("assets/img/warranty.png")}
        alt="Warranty Icon"
        className={classes.successIcon}
      />
      <h3 className={classes.successMessage}>
        Warranties for order #{orderId} has been linked successfully
      </h3>
    </div>
  );

  return (
    <GridContainer className={classes.cardBody}>
      <GridItem xs={12} sm={12} md={12}>
        <Card raised>
          <CardBody>
            <CustomModal
              open={isOpen}
              onClose={onClose}
              modalInnerContainerClassName={classes.modal}
            >
              <div id="reader" />
            </CustomModal>

            {error ? <p>{error}</p> : null}

            {isLinkingCompleted ? successScreen : defaultScreen}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
