import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import styles from "./autocompleteInputStyle.js";
import throttle from "lodash/throttle";
import { useHttpClient } from "shared/hooks/http-hook";

const useStyles = makeStyles(styles);
const filter = createFilterOptions();

export default function AutocompleteInput(props) {
  const classes = useStyles();
  const {
    options: optionsProp,
    id,
    formControlProps,
    error,
    helperText,
    labelText,
    selectedValue,
    onChange,
    multiple,
    shrink,
    addNew,
    isTableInput,
    isLoading: isLoadingProp,
    onFocus,
    getOptionLabel,
    getData,
    filters,
    shouldThrottle,
    ...rest
  } = props;

  const [sendRequest, isGetLoading] = useHttpClient();

  const [value, setValue] = React.useState(multiple ? [] : null);
  const [options, setOptions] = React.useState(optionsProp || []);
  const [inputValue, setInputValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(true);

  const inputRef = React.useRef(null);

  const fetch = React.useMemo(
    () =>
      throttle(async (request, callback) => {
        try {
          const response = await sendRequest(getData(1, 50, request, filters));
          callback(response?.data || []);
        } catch {}
      }, 1000),
    [filters]
  );

  React.useEffect(() => {
    if (shouldThrottle) {
      let active = true;
      fetch(inputValue, (results) => {
        if (active) {
          let newOptions = [];
          if (inputValue) {
            newOptions = [inputValue];
          }
          if (results) {
            newOptions = [...newOptions, ...results];
          }
          setOptions(newOptions);
        }
      });
      return () => {
        active = false;
      };
    }
  }, [inputValue, fetch]);

  React.useEffect(() => {
    if (optionsProp) {
      setOptions(optionsProp);
    }
  }, [optionsProp]);

  React.useEffect(() => {
    if (isLoadingProp === undefined) {
      setIsLoading(isGetLoading);
    } else {
      setIsLoading(isLoadingProp);
    }
  }, [isLoadingProp, isGetLoading]);

  React.useEffect(() => {
    if ((multiple && selectedValue) || !multiple) {
      setValue(
        typeof selectedValue === "number"
          ? selectedValue.toString()
          : selectedValue
      );
    }
  }, [selectedValue]);

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      isTableInput ? classes.tableInputFormControl : classes.formControl
    );
  } else {
    formControlClasses = isTableInput
      ? classes.tableInputFormControl
      : classes.formControl;
  }
  var helpTextClasses = classNames({
    [classes.labelRootError]: error,
  });

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underline]: true,
  });

  const inputClasses = classNames({
    [classes.input]: true,
  });

  const labelClasses = classNames({
    [classes.labelRootError]: error,
    [classes.labelRoot]: true,
  });

  const onGroupClickHandler = React.useCallback(
    (params) => {
      const groupOptions = options?.filter(
        (option) => option.categoryName === params.group
      );
      // Get all the options in the selected group that are not already selected in the current
      // value to push them in the current value
      const difference = groupOptions?.filter((el) => !value.includes(el));

      if (difference?.length > 0) {
        const tempValue = value;
        tempValue.push(...difference);
        onChange(undefined, tempValue);
        setValue(tempValue);
      } else {
        // If the user clicks on an already selected category, the following
        // filters out all the the options of the selected group from the current value
        const tempValue = value?.filter((el) => !groupOptions.includes(el));
        onChange(undefined, tempValue);
        setValue(tempValue);
      }
      inputRef.current.blur();
    },
    [value, setValue, onChange, inputRef]
  );

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      <Autocomplete
        openOnFocus
        clearOnEscape
        clearOnBlur
        autoHighlight
        options={
          isLoading
            ? [{ name: "Loading...", isLoading: true, disabled: true }]
            : options
        }
        value={value}
        onChange={onChange}
        id={id}
        classes={{
          inputRoot: underlineClasses,
          input: inputClasses,
        }}
        renderGroup={(params) => {
          return (
            <div>
              <div
                className={classes.groupName}
                onClick={() => onGroupClickHandler(params)}
              >
                {params.group}
              </div>
              {params.children}
            </div>
          );
        }}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              autoComplete="off"
              inputProps={{
                ...params.inputProps,
                autocomplete: "off",
                onFocus,
                // form: {
                //   autocomplete: "off",
                // },
                onChange: (event) => {
                  const newValue = event.target.value;
                  setInputValue(newValue);
                  params.inputProps.onChange(event);
                },
              }}
              label={labelText}
              InputLabelProps={{
                className: labelClasses,
                shrink,
              }}
              margin="normal"
            />
          );
        }}
        multiple={multiple}
        {...rest}
        blurOnSelect={false}
        getOptionDisabled={(option) =>
          !!option?.isLoading || !!option?.disabled
        }
        getOptionLabel={(option) => {
          if (option?.addNew || option?.isLoading) return option.name;
          return getOptionLabel(option);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (addNew) {
            filtered.push({
              addNew: true,
              name: `＋ Add new`,
            });
          }
          return filtered;
        }}
        ListboxProps={
          isTableInput && {
            style: {
              maxHeight: "500px",
            },
          }
        }
      />
      {helperText !== undefined ? (
        <FormHelperText id={id + "-text"} className={helpTextClasses}>
          {helperText}
        </FormHelperText>
      ) : null}
    </FormControl>
  );
}

Autocomplete.propTypes = {
  options: PropTypes.array,
  labelText: PropTypes.node,
  id: PropTypes.string,
  formControlProps: PropTypes.object,
  error: PropTypes.bool,
  helperText: PropTypes.node,
  shrink: PropTypes.bool,
  getOptionLabel: PropTypes.func,
};
