import Tooltip from "@material-ui/core/Tooltip";
import AutocompleteInput from "components/AutocompleteInput/AutocompleteInput";
import CheckboxGroup from "components/CheckboxGroup/CheckboxGroup";
import CustomInput from "components/CustomInput/CustomInput";
import RichTextInput from "components/CustomInput/RichTextInput";
import CustomSwitch from "components/CustomSwitch/CustomSwitch";
import ImageUpload from "components/CustomUpload/ImageUpload";
import DropFiles from "components/DropFiles/DropFiles";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import AddNewOptionModal from "components/GenericInput/AddNewOptionModal/AddNewOptionModal";
import SelectInput from "components/SelectInput/SelectInput";
import enums from "enums";
import useGenericInput from "./GenericInput.hook";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./GenericInput.styles";
import { applyTimezoneOffset } from "utils/index";

const useStyles = makeStyles(styles);

export default function GenericInput(props) {
  const { type, name, label, options = {}, error, value, onChange } = props;
  const isTableInput = options.isTableInput;

  const {
    data,
    isLoading,
    isCreateModalOpen,
    shouldTooltipBeEnabled,
    fetchRemoteData,
    handleCreateModalClose,
    handleAutocompleteChange,
  } = useGenericInput({ type, label, options, value, onChange, name });

  const classes = useStyles();

  return (
    <Tooltip
      title={options?.disabledTooltipText}
      placement="bottom"
      disableFocusListener={!shouldTooltipBeEnabled}
      disableHoverListener={!shouldTooltipBeEnabled}
      disableTouchListener={!shouldTooltipBeEnabled}
      classes={{ tooltip: classes.tooltip }}
      arrow
    >
      <div style={{ width: "100%" }}>
        {type === "text" ||
        type === "number" ||
        type === "date" ||
        type === "datetime-local" ||
        type === "time" ||
        type === "month" ? (
          <CustomInput
            formControlProps={{ fullWidth: true }}
            inputProps={{
              onChange: (e) => {
                e.target.value =
                  e.target.value === NaN ? undefined : e.target.value;
                onChange(e);
              },
              value:
                type === "date"
                  ? value?.split("T")[0]
                  : type === "month"
                  ? value?.split("T")[0]?.substring(0, 7)
                  : type === "datetime-local"
                  ? applyTimezoneOffset(value)
                  : value,
              type,
              multiline: options?.multiline,
              rows: options?.multiline ? (options.rows ? options.rows : 5) : 1,
              shrink: options?.shrink,
            }}
            disabled={options.disabled}
            labelText={label}
            error={!!error}
            helperText={error}
            name={name}
            isTableInput={isTableInput}
            inputCustomClasses={options.inputCustomClasses}
            variant={options.variant}
          />
        ) : type === "select" ? (
          <SelectInput
            id={name}
            labelText={label}
            placeholder={label}
            error={!!error}
            errorMessage={error}
            data={data}
            selectedValue={value}
            onSelect={onChange}
            shrink={options?.shrink}
            disabled={options?.disabled}
            isTableInput={isTableInput}
          />
        ) : type === "checkboxGroup" ? (
          <CheckboxGroup
            label={label}
            data={data}
            value={value}
            onChange={onChange}
            isTableInput={isTableInput}
          />
        ) : type === "autocomplete" ? (
          <>
            {isCreateModalOpen && options.formComponent ? (
              <AddNewOptionModal
                formComponent={options.formComponent}
                type={enums.FormContainerType.MODAL}
                isOpen={isCreateModalOpen}
                onClose={handleCreateModalClose}
              />
            ) : null}
            {isCreateModalOpen && options.formStructure ? (
              <FormContainer
                entityName={label}
                dataResource={options.dataResource}
                formStructure={options.formStructure}
                type={enums.FormContainerType.MODAL}
                create
                isOpen={isCreateModalOpen}
                onClose={handleCreateModalClose}
                defaultValues={options.defaultValues}
              />
            ) : null}
            <AutocompleteInput
              getOptionDisabled={
                options.multiple
                  ? (option) =>
                      value?.some((selectedEl) => {
                        if (typeof option === "object")
                          return selectedEl.id === option.id;
                        else return selectedEl === option;
                      })
                  : undefined
              }
              formControlProps={{ fullWidth: true }}
              options={options?.shouldThrottle ? [] : data}
              id={name}
              labelText={label}
              selectedValue={value}
              onChange={handleAutocompleteChange}
              error={!!error}
              helperText={error}
              getOptionLabel={
                options.getOptionLabel
                  ? options.getOptionLabel
                  : (option) => {
                      if (typeof option === "object") {
                        return option.name;
                      }
                      return option;
                    }
              }
              addNew={!!options.formStructure || !!options.formComponent}
              isTableInput={isTableInput}
              onFocus={options?.loadOnFocus ? fetchRemoteData : () => {}}
              isLoading={options?.loadOnFocus ? isLoading : undefined}
              {...options}
            />
          </>
        ) : type === "switch" ? (
          <CustomSwitch value={value} onChange={onChange} labelText={label} />
        ) : type === "rich-text" ? (
          <RichTextInput
            value={value}
            setValue={options.setRteValue}
            labelText={label}
            onChange={onChange}
            dir={options.rteDir}
            {...options}
          />
        ) : type === "image" ? (
          <ImageUpload
            label={label}
            file={value}
            setFile={onChange}
            addButtonProps={{
              color: "primary",
              round: true,
            }}
            changeButtonProps={{
              color: "primary",
              round: true,
            }}
            removeButtonProps={{
              color: "danger",
              round: true,
            }}
            isTableInput={isTableInput}
          />
        ) : type === "dropzone" ? (
          <DropFiles
            files={value ? value : []}
            setFiles={onChange}
            maxFiles={options?.maxFiles}
            onRemoveAttachment={options?.onRemoveAttachment}
            onAddAttachments={options?.onAddAttachments}
            label={label}
            errorMessage={error}
            shouldUploadTemp={
              options?.shouldUploadTemp === false ? false : true
            }
            names={options?.names}
          />
        ) : type === "boolean" ? (
          <SelectInput
            id={name}
            labelText={label}
            placeholder={label}
            error={!!error}
            errorMessage={error}
            data={[
              ...(options.addAllChoice ? [{ name: "All", value: null }] : []),
              { name: "Yes", value: true },
              { name: "No", value: false },
            ]}
            selectedValue={value}
            onSelect={onChange}
            shrink={options?.shrink}
          />
        ) : null}
      </div>
    </Tooltip>
  );
}
