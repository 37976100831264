import { Hidden } from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Divider from "@material-ui/core/Divider";
import Grow from "@material-ui/core/Grow";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import { makeStyles } from "@material-ui/core/styles";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React from "react";
import { AuthContext } from "shared/context/auth-context";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const { rtlActive } = props;

  const auth = React.useContext(AuthContext);

  const [openProfile, setOpenProfile] = React.useState(null);

  const classes = useStyles();

  const handleLogout = () => auth.logout();

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };

  const handleCloseProfile = () => {
    setOpenProfile(null);
  };

  const dropdownItem = classNames(classes.dropdownItem, {
    [classes.dropdownItemRTL]: rtlActive,
  });
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive,
  });
  const managerClasses = classNames({
    [classes.managerClasses]: true,
  });

  const dropdownItems = (
    <MenuList role="menu">
      <Divider light />
      <MenuItem onClick={handleLogout} className={dropdownItem}>
        {rtlActive ? "الخروج" : "Log out"}
      </MenuItem>
    </MenuList>
  );

  return (
    <div className={wrapper}>
      <div className={managerClasses}>
        <div style={{ display: "flex" }}>
          <Button
            color="transparent"
            aria-label="Person"
            aria-owns={openProfile ? "profile-menu-list" : null}
            aria-haspopup="true"
            onClick={handleClickProfile}
            className={classes.buttonLink}
          >
            <div className={classes.buttonChildrenContainer}>
              <AccountCircleIcon
                className={
                  classes.headerLinksSvg +
                  " " +
                  (rtlActive
                    ? classes.links + " " + classes.linksRTL
                    : classes.links)
                }
              />
              <div className={classes.nameContainer}>
                <div className={classes.name}>{auth?.userData?.name}</div>
                <div className={classes.role}>{`${
                  auth?.userData?.roleCode
                    ? translations.en.roleCode[auth?.userData?.roleCode]
                    : ""
                }`}</div>
              </div>
            </div>
          </Button>
        </div>
        <Hidden smDown>
          <Popper
            open={Boolean(openProfile)}
            anchorEl={openProfile}
            transition
            disablePortal
            placement="bottom"
            className={classNames({
              [classes.popperClose]: !openProfile,
              [classes.popperResponsive]: true,
              [classes.popperNav]: true,
            })}
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                id="profile-menu-list"
                style={{ transformOrigin: "0 0 0" }}
              >
                <Paper className={classes.dropdown}>
                  <ClickAwayListener onClickAway={handleCloseProfile}>
                    {dropdownItems}
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Hidden>
        <Hidden mdUp>
          {dropdownItems}
          <Divider light />
        </Hidden>
      </div>
    </div>
  );
}

HeaderLinks.propTypes = {
  rtlActive: PropTypes.bool,
};
