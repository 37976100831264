const customInputStyle = {
  root: {
    minHeight: 30,
    width: "100%",
  },
  focus: {
    boxShadow: "0 0 0 3px rgb(66 153 225 / 0.5)",
    borderWidth: 1,
    borderColor: "#90cdf4",
  },
};

export default customInputStyle;
