import icon from "@material-ui/icons/VerifiedUser";
import enums from "enums/index";
import warrantiesRoutes from "./warrantiesRoutes";

export default {
  name: "Warranties",
  icon,
  layout: "/admin",
  collapse: true,
  state: "warrantiesCollapse",
  resource: enums.Resource.WARRANTIES,
  views: [
    { ...warrantiesRoutes },
  ],
};
