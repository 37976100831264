import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import style from "./fileCardStyle";
import { getMaterialFileIcon } from "file-extension-icon-js";
import { getSizeString } from "utils";
import CloudDownloadRoundedIcon from "@material-ui/icons/CloudDownloadRounded";

const useStyles = makeStyles(style);

export default function FileCard(props) {
  const { name, size, url, rootStyle } = props;

  const classes = useStyles();

  return (
    <div style={rootStyle} className={classes.root}>
      <img
        className={classes.icon}
        src={`${getMaterialFileIcon(name)}`}
        alt="..."
        width="24"
      />
      <div className={classes.infoContainer}>
        <p className={classes.name}>{name}</p>
        {size ? <p className={classes.size}>{getSizeString(size)}</p> : null}
      </div>
      <div className={classes.buttonsContainer}>
        {url ? (
          <a target="_blank" href={decodeURIComponent(url)} download>
            <CloudDownloadRoundedIcon className={classes.downloadIcon} />
          </a>
        ) : null}
      </div>
    </div>
  );
}
