import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import Button from "components/CustomButtons/Button.js";
import PropTypes from "prop-types";
import React from "react";
import { useHttpClient } from "shared/hooks/http-hook";

export default function ConfirmationButton(props) {
  const {
    name,
    id,
    api,
    entityName,
    redirectPath,
    refreshOnSuccess,
    buttonProps,
    onConfirm,
    onSuccess,
    children,
    confirmMessage,
    successMessage,
    headerMessage,
    successHeaderText,
    confirmButtonText,
    confirmButtonColor,
    loading,
    skipSuccessScreen,
  } = props;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [activeScreen, setActiveScreen] = React.useState("question");

  const [sendRequest] = useHttpClient();

  const [isLoading, setIsLoading] = React.useState(false);

  const handleConfirm = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      if (onConfirm) {
        await onConfirm();
      } else {
        await sendRequest(api(id));
      }
      if (!skipSuccessScreen) {
        setActiveScreen("success");
        return;
      }
      if (onSuccess) {
        onSuccess();
      }
      setModalOpen(false);
    } catch (err) {
      console.log(err)
      setActiveScreen("error");
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <React.Fragment>
      <Button
        deleteButton={children ? false : true}
        onClick={() => {
          setModalOpen(true);
        }}
        color="white"
        textColor="danger"
        style={buttonProps.justIcon ? { margin: "0 5px" } : {}}
        {...buttonProps}
      >
        {children}
      </Button>
      {modalOpen && (
        <ConfirmModal
          isOpen={modalOpen}
          onClose={() => {
            setModalOpen(false);
            setTimeout(() => {
              setActiveScreen("question");
            }, 300);
          }}
          headerMessage={headerMessage || `Delete ${entityName}`}
          successHeaderText={successHeaderText || "Deleted successfully"}
          confirmMessage={
            confirmMessage || `Are you sure you want to delete ${name}?`
          }
          errorMessage="An error has occurred"
          successMessage={
            successMessage || `${entityName} has been deleted successfully.`
          }
          confirmButtonText={confirmButtonText || "Delete"}
          confirmButtonColor={confirmButtonColor || "danger"}
          cancelButtonText="Cancel"
          onConfirm={handleConfirm}
          loading={loading || isLoading}
          activeScreen={activeScreen}
          refreshOnSuccess={refreshOnSuccess}
          redirectPath={redirectPath}
          onSuccess={onSuccess}
          skipSuccessScreen={skipSuccessScreen}
        />
      )}
    </React.Fragment>
  );
}

ConfirmationButton.defaultProps = {
  buttonProps: {},
  skipSuccessScreen: false,
}

ConfirmationButton.propTypes = {
  api: PropTypes.func,
  id: PropTypes.any,
  name: PropTypes.string,
  entityName: PropTypes.string,
  redirectPath: PropTypes.string,
  refreshOnSuccess: PropTypes.bool,
  onConfirm: PropTypes.func,
  buttonProps: PropTypes.object,
  confirmMessage: PropTypes.string,
  successMessage: PropTypes.string,
  headerMessage: PropTypes.string,
  confirmButtonText: PropTypes.string,
  successHeaderText: PropTypes.string,
  confirmButtonColor: PropTypes.string,
  loading: PropTypes.bool,
  skipSuccessScreen: PropTypes.bool,
};
