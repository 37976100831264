export default {
  FormContainerType: { PAGE: "PAGE", MODAL: "MODAL" },
  Action: {
    CREATE: "create",
    READ: "read",
    UPDATE: "update",
    DELETE: "delete",
  },
  Resource: {
    ACCESS_MANAGEMENT: "ACCESS_MANAGEMENT",
    WARRANTIES: "WARRANTIES",
    ORDERS_MANAGEMENT: "ORDERS_MANAGEMENT",
    ITEMS_MANAGEMENT: "ITEMS_MANAGEMENT",
    RETAILERS: "RETAILERS",
  },
  RoleCode: {
    SUPER_ADMIN: "SUPER_ADMIN",
    OPS_MANAGER: "OPS_MANAGER",
  },
  InfoType: {
    TEXT: "TEXT",
    DATE: "DATE",
    BOOLEAN: "BOOLEAN",
    LIST: "LIST",
    LINK: "LINK",
    EXTERNAL_LINK: "EXTERNAL_LINK",
    FILE: "FILE",
    TAGS: "TAGS",
  },
  ItemType: {
    SCRAP: "SCRAP",
    NEW: "NEW",
  },
  OrderStatus: {
    LEAD: "LEAD",
    PLANNED: "PLANNED",
    ASSIGNED: "ASSIGNED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    LOST: "LOST",
  },
  WarrantyBatchStatus: {
    PENDING_PRINTING: "PENDING_PRINTING",
    PRINTED: "PRINTED",
  },
  WarrantyStatus: {
    UNLINKED: "UNLINKED",
    LINKED: "LINKED",
    ACTIVE: "ACTIVE",
    EXPIRED: "EXPIRED",
  },
  OrderStatus: {
    LEAD: "LEAD",
    PLANNED: "PLANNED",
    ASSIGNED: "ASSIGNED",
    IN_PROGRESS: "IN_PROGRESS",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    LOST: "LOST",
  },
  OrderItemStatus: {
    PENDING: "PENDING",
    SKIPPED: "SKIPPED",
    LINKED: "LINKED",
  },
  ItemGroup: {
    BATTERIES: "BATTERIES",
    WB: "WB",
    TOOLS_AND_ACCESSORIES: "TOOLS_AND_ACCESSORIES",
  },
  ItemType: {
    NEW: "NEW",
    SCRAP: "SCRAP",
  },
  OrderWarrantyLinkAction: {
    ACTIVATE: "ACTIVATE",
    CANCEL: "CANCEL",
    COMPLETE: "COMPLETE",
  },
};
