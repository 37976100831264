import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import OrderItemModal from "./components/OrderItemModal/OrderItemModal";
import useOrderItems from "./OrderItems.hook";
import styles from "./OrderItems.styles";
import { Controller } from "react-hook-form";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderItems({ form, order: orderProp }) {
  const classes = useStyles();

  const { control, errors } = form;

  const {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    orderItems,
    onAssignModalClose,
    handleAssignItem,
    onRemoveModalClose,
    onAdd,
    onEdit,
  } = useOrderItems({ form, orderProp });

  const columns = React.useMemo(
    () => [
      {
        title: "SAP Code",
        dataIndex: ["product", "sapCode"],
      },
      {
        title: "Description",
        dataIndex: ["product", "description"],
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
        render: (_, record) => {
          if (record.id) return record.unitPrice;
          return record.product.unitPrice;
        },
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Discount",
        render: (_, record) => {
          const promoCode = record.promoCode
            ? {
                type: record.promoCodeType || record.promoCode.type,
                amount:
                  record.promoCodeDiscountAmount || record.promoCode.amount,

                code: record.promoCode.code,
              }
            : {};
          return (
            <div>
              {promoCode.type === enums.PromoCodeType.PERCENTAGE ? (
                <div>
                  {promoCode.code}
                  {` (${promoCode.amount}%)`}
                </div>
              ) : record.promoCodeType === enums.PromoCodeType.FIXED ? (
                <div>
                  {promoCode.code}
                  {` (-${promoCode.amount} EGP)`}
                </div>
              ) : (
                <div>-</div>
              )}
            </div>
          );
        },
      },

      {
        title: "Total Price",
        render: (_, record) => {
          return (
            <div>
              {record.totalPriceBeforeDiscount !== record.totalPrice ? (
                <div>
                  <strike style={{ color: "red" }}>
                    {record.totalPriceBeforeDiscount}
                  </strike>
                  <span> {record.totalPrice}</span>
                </div>
              ) : (
                <div>{record.totalPrice}</div>
              )}
            </div>
          );
        },
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <div className={classes.actionsContainer}>
              <CheckPermission action={enums.Action.UPDATE}>
                <Button
                  justIcon
                  edit
                  color="white"
                  textColor="success"
                  onClick={() => handleAssignItem(record)}
                />
              </CheckPermission>
              <CheckPermission action={enums.Action.DELETE}>
                <ConfirmationButton
                  name="this item"
                  entityName="Item"
                  onConfirm={() => {}}
                  onSuccess={() => onRemoveModalClose(record)}
                  buttonProps={{
                    justIcon: true,
                  }}
                  skipSuccessScreen
                />
              </CheckPermission>
            </div>
          );
        },
      },
    ],
    [order]
  );

  return (
    <Controller
      render={(props) => (
        <div className={classes.root}>
          <div className={classes.section}>
            {isAssignModalOpen && (
              <OrderItemModal
                isOpen={isAssignModalOpen}
                onClose={onAssignModalClose}
                create={!editedItem}
                edit={!!editedItem}
                item={editedItem}
                onAdd={onAdd}
                onEdit={onEdit}
                order={order}
              />
            )}
            <React.Fragment>
              <div className={classes.headerContainer}>
                <h3 className={classes.header}>Order Items</h3>
                <CheckPermission action={enums.Action.CREATE}>
                  <Button
                    onClick={() => handleAssignItem()}
                    className={classes.addButton}
                    color="success"
                  >
                    <AddIcon className={classes.addIcon} />
                    Add Order Item
                  </Button>
                </CheckPermission>
              </div>
              <div className={classes.tableContainer}>
                <CustomTable
                  columns={columns}
                  data={orderItems}
                  loading={isGetOrderLoading}
                  addIndexColumn
                />
              </div>
            </React.Fragment>
            <div className={classes.error}>{errors?.items?.message}</div>
          </div>
        </div>
      )}
      name="items"
      control={control}
    />
  );
}
