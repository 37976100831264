import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import api from "services/api";
import styles from "./AddItemModal.styles";
import useAddItemModal from "./AddItemModal.hook";
import translations from "constants/translations/index";
import manufacturersForm from "views/ItemsManagement/manufacturersForm";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddItemModal(props) {
  const { isOpen, onClose, successCallback } = props;

  const classes = useStyles();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
  } = useAddItemModal({
    onClose,
    successCallback,
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <AddIcon className={classes.icon} /> Add Stock
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  form={form}
                  name="sapCode"
                  type="text"
                  label="SAP Code"
                />
                <FormField
                  md={12}
                  form={form}
                  name="group"
                  type="select"
                  label="Group"
                  options={{
                    data: Object.keys(translations["en"].itemGroup).map(
                      (key) => ({
                        value: key,
                        name: translations["en"].itemGroup[key],
                      })
                    ),
                  }}
                />
                <FormField
                  md={12}
                  form={form}
                  name="type"
                  type="select"
                  label="Type"
                  options={{
                    data: Object.keys(translations["en"].itemType).map(
                      (key) => ({
                        value: key,
                        name: translations["en"].itemType[key],
                      })
                    ),
                  }}
                />
                <FormField
                  md={12}
                  form={form}
                  name="description"
                  type="text"
                  label="Description"
                  options={{
                    multiline: true,
                    rows: 5,
                  }}
                />
                <FormField
                  md={12}
                  form={form}
                  name="unitPrice"
                  type="number"
                  label="Unit Price"
                />
                <FormField
                  md={12}
                  form={form}
                  name="unitCost"
                  type="number"
                  label="Unit Cost"
                />
                <FormField
                  md={12}
                  form={form}
                  name="manufacturer"
                  fetchedName="manufacturer"
                  type="autocomplete"
                  label="Manufacturer"
                  options={{
                    getData: () => api.manufacturers.getAll(),
                    getOptionLabel: (option) => option.name,
                    getSubmitValue: (option) => option?.id,
                    addNew: true,
                    formStructure: manufacturersForm,
                    freeSolo: true,
                    dataResource: api.manufacturers,
                    blurOnSelect: false,
                  }}
                />
                <FormField
                  md={12}
                  form={form}
                  name="serialRequired"
                  type="boolean"
                  label="Serial Required"
                />
                <FormField
                  md={12}
                  form={form}
                  name="warrantyExpirationPeriod"
                  type="number"
                  label="Warranty Expiration Period (months)"
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddItemModal.defaultProps = {
  onClose: () => {},
};

AddItemModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  successCallback: PropTypes.func,
};
