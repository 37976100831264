const styles = (theme) => ({
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    margin: 0,
    maxWidth: 800,
  },
  formContainer: { marginTop: -30, marginBottom: -30 },
});

export default styles;
