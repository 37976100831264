import {
  blackColor,
  successColor,
  whiteColor,
} from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modalHeader: {
    fontWeight: 700,
  },
  success: {
    color: successColor[0],
  },
  modalFooter: {
    padding: "15px",
    paddingBottom: 5,
    marginTop: "auto",
    textAlign: "center",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  button: {
    padding: "5px 20px",
    borderRadius: 5,
    marginLeft: 20,
    "& > span:first-child": {
      fontSize: 16,
      color: whiteColor,
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  cancel: {
    "& > span:first-child": {
      color: blackColor,
    },
    marginLeft: 0,
  },
  root: {
    padding: "40px 20px",
    paddingBottom: 0,
  },
  section: {
    width: "100%",
    marginBottom: 80,
  },
  headerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    marginBottom: -10,
  },
  header: {
    margin: 0,
    marginLeft: 10,
  },
  buttonsContainer: { display: "flex" },
  addButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  addIcon: {
    width: "16px",
    zIndex: "4",
    color: "white",
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
    marginBottom: 2,
    marginRight: 5,
  },
  modalRoot: {
    padding: "20px",
    paddingBottom: 0,
    display: "flex",
    backgroundColor: whiteColor,
    boxShadow: "0 0 24px 0 rgba(0, 0, 0, 0.5)",
    minWidth: "unset",
  },
  modalTitle: { fontWeight: 500, color: "black", marginBottom: 20 },
  qrCodeContainer: { marginBottom: 40 },
  tableContainer: {
    width: "100%",
    marginTop: 25,
  },
});

export default styles;
