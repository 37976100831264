import {
  dangerColor,
  successColor,
  defaultFont,
  whiteColor,
  grayColor,
  blackColor,
} from "assets/jss/material-dashboard-pro-react.js";

const autocompleteInputStyle = {
  groupName: {
    padding: 5,
    cursor: "pointer",
    fontWeight: 500,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  disabled: {
    "&:before": {
      borderColor: "transparent !important",
    },
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + "!important",
      borderWidth: "1px !important",
    },
    "&:after": {
      // borderColor: primaryColor[0],
      borderColor: blackColor,
    },
    "& + p": {
      fontWeight: "300",
    },
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor[0],
    },
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor[0],
    },
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: -16,
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px",
    },
  },
  labelRootError: {
    color: dangerColor[0] + " !important",
    fontWeight: 300,
    marginTop: -2,
  },
  labelRootSuccess: {
    color: successColor[0] + " !important",
  },
  formControl: {
    margin: "0 0 9px 0",
    paddingTop: "8px",
    position: "relative",
    verticalAlign: "unset",
    transition: "all 0.3s",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14],
    },
  },
  tableInputFormControl: {
    margin: "0 0 0 0",
    paddingTop: "0",
    position: "relative",
    verticalAlign: "unset",
    transition: "all 0.3s",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: grayColor[14],
    },
    "& .MuiFormControl-marginNormal": {
      marginTop: "0",
      marginBottom: "0",
    },
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      backgroundColor: whiteColor,
    },
    "&:after": {
      backgroundColor: whiteColor,
    },
  },
  input: {
    color: grayColor[14],
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1",
    },
    "&::placeholder": {
      color: grayColor[3],
    },
  },
  whiteInput: {
    "&,&::placeholder": {
      color: whiteColor,
      opacity: "1",
    },
  },
};

export default autocompleteInputStyle;
