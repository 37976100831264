import { makeStyles } from "@material-ui/core/styles";
import login from "assets/img/login.jpeg";
import styles from "assets/jss/material-dashboard-pro-react/layouts/appStyle";
import Footer from "components/Footer/Footer.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      let childrenRoutes = [];
      if (prop.children) {
        childrenRoutes = getRoutes(prop.views);
      }
      if (prop.layout === "/app") {
        return [
          ...childrenRoutes,
          <Route
            path={prop.layout + prop.path}
            render={prop.render}
            component={prop.component}
            key={prop.randomKey ? Math.random() : "route - " + key}
          />,
        ];
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div className={classes.fullPage}>
          <div className={classes.container}>
            <Switch>
              {getRoutes(routes)}
              <Redirect from="/app" to="/app/warranties-linking" />
            </Switch>
          </div>
          <Footer fluid />
        </div>
      </div>
    </div>
  );
}
