import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import React from "react";
import styles from "./CheckboxInput.style";

const useStyles = makeStyles(styles);

export default function CheckboxInput(props) {
  const { value, label, onClick, notHorizontal } = props;

  const classes = useStyles();
  return (
    <div
      className={
        notHorizontal
          ? classes.checkboxAndRadio
          : classes.checkboxAndRadio + " " + classes.checkboxAndRadioHorizontal
      }
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            tabIndex={-1}
            onClick={onClick}
            checkedIcon={<Check className={classes.checkedIcon} />}
            icon={<Check className={classes.uncheckedIcon} />}
            classes={{
              checked: classes.checked,
              root: classes.checkRoot,
            }}
          />
        }
        classes={{
          label: classes.label,
          root: classes.labelRoot,
        }}
        label={label}
      />
    </div>
  );
}
