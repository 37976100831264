const filesListStyle = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 10,
  },
  paginationContainer: {
    margin: "10px auto",
  },
});

export default filesListStyle;
