import qs from "qs";
import React from "react";
import { useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import {
  flattenObject,
  injectFilterOperators,
  setPageSettingsProp,
} from "utils";

//------------------------------------------------------------------------------

export default function useItems(props) {
  const { filterInputs } = props;

  const history = useHistory();
  const match = useRouteMatch();

  const [currentPage, setCurrentPage] = React.useState(1);
  const [listData, setListData] = React.useState({ count: 0, rows: [] });
  const [searchQuery, setSearchQuery] = React.useState("");
  const [filtersState, setFiltersState] = React.useState({});
  const [deleteItemId, setDeleteItemId] = React.useState(null);
  const [itemsPerPage, setItemsPerPage] = React.useState(10);
  const [highlightedText, setHighlightedText] = React.useState("");
  const [
    isAddItemModalOpen,
    setIsAddItemModalOpen,
  ] = React.useState(false);
  const [
    isAddStockModalOpen,
    setIsAddStockModalOpen,
  ] = React.useState(false);

  const [sendGetRequest, isGetLoading] = useHttpClient();

  const getData = React.useCallback(
    async (
      page = currentPage,
      rowsPerPage = itemsPerPage,
      search = searchQuery,
      filters = filtersState
    ) => {
      const processedFilters = filterInputs
        ? injectFilterOperators(filters, filterInputs)
        : undefined;
      try {
        const response = await sendGetRequest(
          api.stockItems.getAll(
            page,
            rowsPerPage,
            search,
            processedFilters
          )
        );
        setListData({
          count: response.count,
          rows: response.data,
        });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [
      filterInputs,
      sendGetRequest,
      setListData,
      currentPage,
      searchQuery,
      filtersState,
      itemsPerPage,
    ]
  );

  React.useEffect(() => {
    (async () => {
      try {
        const settings = JSON.parse(localStorage.getItem("settings"));
        const rowsPerPage =
          settings && settings[match.path] && settings[match.path].rowsPerPage
            ? settings[match.path].rowsPerPage
            : 10;

        setItemsPerPage(rowsPerPage);

        let { page, search, filters } = qs.parse(
          window.location.search?.split("?")[1]
        );

        setSearchQuery(search ? search : "");
        const tempFilters = {};
        if (filterInputs) {
          filters = filters ? flattenObject(filters) : {};
          filterInputs.forEach(
            (input, index) =>
              (tempFilters[input.name] = filters[input.name]
                ? filters[input.name]
                : filterInputs[index].defaultValue !== undefined
                ? filterInputs[index].defaultValue
                : null)
          );
          setFiltersState(tempFilters);
        }

        setHighlightedText(search ? search : "");

        if (page) {
          setCurrentPage(parseInt(page));
          await getData(page, rowsPerPage, search, tempFilters);
        } else {
          page = 1;
          history.push({
            pathname: window.location.pathname,
            search: `?page=${page}`,
          });
          await getData(page, rowsPerPage, search, tempFilters);
        }
      } catch (err) {
        toast.error("An error has occurred");
      }
    })();
  }, []);

  React.useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        search: searchQuery,
        filters: filtersState,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () =>
        await getData(lastPage, itemsPerPage, searchQuery, filtersState))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const onFilterChange = (updatedFilters) => {
    setFiltersState(updatedFilters);
  };

  const handleChange = async (pagination) => {
    setPageSettingsProp(`${match.path}.rowsPerPage`, pagination.pageSize);
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      search: searchQuery,
      filters: filtersState,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(
      pagination.current,
      pagination.pageSize,
      searchQuery,
      filtersState
    );
  };

  const handleSubmit = async (search, filters) => {
    setHighlightedText(search);
    const query = qs.stringify({
      search,
      filters,
      page: 1,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(1, itemsPerPage, search, filters);
    setCurrentPage(1);
  };

  return {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    deleteItemId,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isAddItemModalOpen,
    isAddStockModalOpen,
    setListData,
    setDeleteItemId,
    handleChange,
    handleSubmit,
    onSearchChange,
    onFilterChange,
    getData,
    setIsAddItemModalOpen,
    setIsAddStockModalOpen,
  };
}
