import enums from "enums/index";
import { useEffect, useState, useCallback, useContext, useMemo } from "react";
import api from "services/api";
import { toast } from "react-toastify";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export default function useOrderItems() {
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const [editedItem, setEditedItem] = useState(null);
  const [items, setItems] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const [warrantyLink, setWarrantyLink] = useState();

  const [currentAction, setCurrentAction] = useState();

  const { order, setOrder, isGetOrderLoading, getLatestOrder } = useContext(
    OrderContext
  );

  const [sendRequest, isQRCodeLoading] = useHttpClient();
  const [sendItemsRequest, isGetItemsLoading] = useHttpClient();

  useEffect(() => {
    if (!order.id) return;
    (async () => {
      try {
        const response = await sendItemsRequest(api.orders.getItems(order?.id));
        setItems(response?.items);
      } catch (err) {}
    })();
  }, [order]);

  const warrantyLinkingUrl = useMemo(() => {
    if (!warrantyLink) return undefined;
    const origin =
      process.env.REACT_APP_ENV === "development"
        ? process.env.REACT_APP_CLIENT_URL
        : window.location.origin;

    return `${origin}/app/warranties-linking?token=${warrantyLink?.token}`;
  }, [warrantyLink]);

  const allItemsLinked = useMemo(
    () =>
      items.reduce((acc, currentItem) => {
        if (!acc) return acc;

        return currentItem.amount === currentItem.numberOfLinkedItems
          ? acc
          : false;
      }, true),
    [items]
  );

  const onClose = () => {
    setIsConfirmModalOpen(true);
  };

  const generateOrderWarrantiesLink = useCallback(async () => {
    try {
      const link = await sendRequest(
        api.orderWarrantiesLinks.getOneByOrderId(1)
      );

      setWarrantyLink(link);
      setIsOpen(true);
    } catch (err) {
      toast.error(err.response?.data?.message || "An error has occurred");
    }
  }, []);

  const handleAssignItem = useCallback(
    (data) => {
      setEditedItem(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedItem]
  );

  const onAssignModalClose = useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedItem(null);
  }, [setIsAssignModalOpen, setEditedItem]);

  const handleRemoveItem = useCallback(
    async (record) => {
      const response = await api.orderItems.delete(record.id);
    },
    [order]
  );

  const onRemoveModalClose = useCallback(
    (record) => {
      const newItems = order.items.filter((item) => record.id !== item.id);
      getLatestOrder();
    },
    [order, getLatestOrder]
  );

  useEffect(() => {
    if (!warrantyLink) return;

    const eventSource = new EventSource(
      `${process.env.REACT_APP_SERVER_URL}/order-warranties-links/subscribe/${warrantyLink?.token}`
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data)?.data;

      if (data?.action === enums.OrderWarrantyLinkAction.ACTIVATE) {
        handleLinkingActivated();
      } else if (data?.action === enums.OrderWarrantyLinkAction.COMPLETE) {
        handleLinkingCompleted();
      } else if (data?.action === enums.OrderWarrantyLinkAction.CANCEL) {
        handleLinkingCancelled();
      }
    };

    return () => {
      eventSource.close();
    };
  }, [warrantyLink]);

  const handleLinkingActivated = () => {
    setCurrentAction(enums.OrderWarrantyLinkAction.ACTIVATE);
  };

  const handleLinkingCompleted = () => {
    setCurrentAction(enums.OrderWarrantyLinkAction.COMPLETE);
  };

  const handleLinkingCancelled = () => {
    setCurrentAction(enums.OrderWarrantyLinkAction.CANCEL);
  };

  const handleConfirmProcessCancellation = () => {
    setIsConfirmModalOpen(false);
    handleLinkingCancelled();
  };

  const handleScanningProcessEnd = () => {
    setIsOpen(false);
    setWarrantyLink(undefined);
    setCurrentAction(undefined);
  };

  return {
    order,
    isOpen,
    isConfirmModalOpen,
    currentAction,
    allItemsLinked,
    isAssignModalOpen,
    isGetItemsLoading,
    editedItem,
    orderItems: items,
    isQRCodeLoading,
    warrantyLinkingUrl,
    onClose,
    setIsConfirmModalOpen,
    onAssignModalClose,
    handleAssignItem,
    generateOrderWarrantiesLink,
    handleConfirmProcessCancellation,
    handleScanningProcessEnd,
  };
}
