import { makeStyles } from "@material-ui/core/styles";
import CheckPermission from "components/CheckPermission/CheckPermission";
import CollapseContainer from "components/CollapseContainer/CollapseContainer";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import translations from "constants/translations/index";
import enums from "enums/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { getDateString, isOrderEditable } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import OrderDetailsForm from "../../OrderDetailsForm/OrderDetailsForm";
import styles from "./OrderDetails.styles";
import { AuthContext } from "shared/context/auth-context";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderDetails() {
  const classes = useStyles();

  const [isEditing, setIsEditing] = React.useState(false);

  const { i18n } = useTranslation();

  const { order } = React.useContext(OrderContext);

  const { userData } = React.useContext(AuthContext);

  const handleEdit = () => {
    setIsEditing(true);
  };

  return isEditing ? (
    <OrderDetailsForm edit={true} setIsEditing={setIsEditing} />
  ) : (
    <div className={classes.root}>
      <div className={classes.headerContainer}>
        <h3 className={classes.header}>Order details</h3>
        <CheckPermission action={enums.Action.UPDATE}>
          {isOrderEditable(order, userData) && (
            <Button onClick={handleEdit} color="primary" edit={true} />
          )}
        </CheckPermission>
      </div>
      <div className={classes.section}>
        <CollapseContainer
          name="info"
          titleComponent={<h3 style={{ margin: 0 }}>Info</h3>}
          startCollapsed={false}
        >
          <GridContainer>
            <InfoWithLabelGridItem
              label="Retailer"
              info={order?.retailer?.name || "-"}
            />
            <InfoWithLabelGridItem
              label="Order Status"
              info={translations[i18n.language].orderStatus[order?.status]}
            />
            <InfoWithLabelGridItem label="Notes" info={order?.notes || "-"} />
            <InfoWithLabelGridItem
              label="Created by"
              info={order?.createdBy?.name || "-"}
            />
            <InfoWithLabelGridItem
              label="Created at"
              info={getDateString(order?.createdAt, "en-EG", true)}
            />
          </GridContainer>
        </CollapseContainer>
      </div>
    </div>
  );
}
