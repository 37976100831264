import { makeStyles } from "@material-ui/core/styles";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { Tag } from "antd";
import {
  successColor,
  twitterColor,
} from "assets/jss/material-dashboard-pro-react";
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Loading from "components/Loading/Loading";
import Tabs from "components/Tabs/Tabs";
import { orderStatusColors } from "constants/colors";
import translations from "constants/translations";
import enums from "enums/index";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { displayCustomerName } from "utils/index";
import OrderDetails from "views/Orders/components/Tabs/OrderDetails/OrderDetails";
import OrderItems from "views/Orders/components/Tabs/OrderItems/OrderItems";
import { OrderContext } from "./Order.context";
import { useOrderView } from "./OrderView.hook";
import styles from "./OrderView.styles";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderView({ path }) {
  const classes = useStyles();

  const {
    order,
    isGetOrderLoading,
    currentTab,
    setOrder,
    setCurrentTab,
    getLatestOrder,
    updateOrderState,
  } = useOrderView({ path });

  const { i18n } = useTranslation();

  const tabs = useMemo(
    () => [
      {
        name: "Details",
        component: <OrderDetails />,
      },
      {
        name: "Items",
        component: <OrderItems />,
      },
    ],
    [order]
  );

  return (
    <OrderContext.Provider
      value={{
        order,
        path,
        isGetOrderLoading,
        setOrder,
        getLatestOrder,
      }}
    >
      <div>
        <div className={classes.upperContainer}>
          <Breadcrumbs />
          <CheckPermission action={enums.Action.CREATE}>
            <Link to={`/admin/${path}/create`}>
              <Button create color="white" />
            </Link>
          </CheckPermission>
        </div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardBody>
                <Loading loading={isGetOrderLoading} style={{ height: "70vh" }}>
                  {order ? (
                    <React.Fragment>
                      <div className={classes.pageHeader}>
                        <div className={classes.infoContainer}>
                          <div className={classes.infoWithIcon}>
                            <AssignmentIcon className={classes.personIcon} />
                            <div className={classes.headerInfoContainer}>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {`Order #${order?.id}`} - &nbsp;
                                <div
                                  style={{
                                    color: orderStatusColors[order?.status],
                                  }}
                                >
                                  {
                                    translations[i18n.language].orderStatus[
                                      order?.status
                                    ]
                                  }
                                </div>
                              </div>
                              <div className={classes.subtitle}>
                                {order?.car
                                  ? `Customer: ${displayCustomerName(
                                      order?.car?.owner
                                    )}`
                                  : ""}
                              </div>
                              <div className={classes.subtitle}>
                                {`Total Price: ${order?.netPrice || 0} EGP`}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Tabs
                        buttonsContainerClasses={classes.tabsContainer}
                        tabs={tabs}
                        value={currentTab}
                        onChange={setCurrentTab}
                      />
                    </React.Fragment>
                  ) : (
                    <div>No available data</div>
                  )}
                </Loading>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </OrderContext.Provider>
  );
}
