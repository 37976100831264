import GenericInput from "components/GenericInput/GenericInput";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import React from "react";
import { Controller } from "react-hook-form";
import Fade from "react-reveal/Fade";
import { convertToBracketNotation } from "utils/index";

export default function FormField(props) {
  const {
    type,
    name,
    value,
    label,
    form,
    options,
    getShowCondition,
    dependencyList,
    rules,
    onChange,
    getInfoText,
    info,
  } = props;

  const { control, setValue, errors, watch } = form;

  const [isShown, setIsShown] = React.useState(false);

  React.useEffect(() => {
    if (getShowCondition && getShowCondition()) {
      setIsShown(true);
    } else if (getShowCondition && !getShowCondition()) {
      setIsShown(false);
      setValue(name, undefined);
    }
  }, [...dependencyList]);

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    if ((type === "date" || type === "datetime-local") && value === "")
      value = null;
    setValue(prop, value, { shouldDirty: true });
    if (onChange) onChange(value);
  };

  const handleNumberChange = (prop) => (event) => {
    setValue(prop, parseFloat(event.target.value), { shouldDirty: true });
    if (onChange) onChange(parseFloat(event.target.value));
  };

  const handleAutoCompleteChange = (prop) => (_, newValue) => {
    setValue(prop, newValue, { shouldDirty: true });
    if (onChange) onChange(newValue);
  };

  const field = (
    <Controller
      render={(props) => {
        return type === "hidden" ? null : type === "info" ? (
          <InfoWithLabel
            label={label}
            info={getInfoText ? getInfoText(watch) : info}
          />
        ) : (
          <GenericInput
            type={type}
            name={name}
            label={label}
            value={props.value}
            error={errors[convertToBracketNotation(name)]?.message}
            onChange={
              type === "text" ||
              type === "date" ||
              type === "datetime-local" ||
              type === "time" ||
              type === "select" ||
              type === "boolean" ||
              type === "month"
                ? handleChange(name)
                : type === "number"
                ? handleNumberChange(name)
                : type === "autocomplete"
                ? handleAutoCompleteChange(name)
                : type === "switch"
                ? () => setValue(name, !props.value)
                : type === "rich-text"
                ? (editorState) => {
                    setValue(
                      name?.split("@")?.length > 1
                        ? `${name?.split("@")[0]}Text@${name?.split("@")[1]}`
                        : `${name}Text`,
                      editorState.getCurrentContent().getPlainText()
                    );
                  }
                : type === "image" || "dropzone"
                ? (file) => setValue(name, file)
                : null
            }
            options={{
              ...options,
              ...(type === "rich-text"
                ? {
                    setRteValue: (value) => setValue(name, value),
                    rteDir: options?.getDirection
                      ? options?.getDirection(watch)
                      : options?.dir
                      ? options?.dir
                      : "ltr",
                  }
                : {}),
            }}
          />
        );
      }}
      name={name}
      control={control}
      rules={rules}
    />
  );

  return getShowCondition === undefined ? (
    field
  ) : (
    <Fade unmountOnExit mountOnEnter collapse when={isShown} duration={700}>
      <div
        style={{
          minHeight:
            55 +
            (options?.multiline
              ? options?.rows
                ? options.rows * 17
                : 5 * 17
              : 0),
        }}
      >
        {field}
      </div>
    </Fade>
  );
}

FormField.defaultProps = {
  dependencyList: [],
};

/* 
- Component props are:

  1. name: field name in form
  2. type: field type
  3. label: displayed field label and placeholder
  4. options: options object of the corresponding type
  5. validation: validation schema for the field
  6. getSubmitValue: function that converts field values before submitting the form
  7. getShowCondition: function that returns a boolean to show the field when true
  8. dependencyList: dependency list that updates visibility status 

  
- type values are:

1. text: 
  uses CustomInput component with type text
  options => {
    multiline: bool
    rows: number
  }

2. number:
  uses CustomInput component with type number

3. date: 
  uses CustomInput component with type date

4. datetime-local: 
  uses CustomInput component with type datetime-local

5. select: 
  uses SelectInput component
  options => {
    data: select options array
    getData: function that populates data prop in SelectInput ,,,, (either send data prop or getData) 
    rawDataConvertor: map to convert fetched values from getData to [{name: "example", value: 0}] (optional)
  }

6. autocomplete: 
  uses AutocompleteInput component
  options => {
    data: autocomplete options array
    getData: function that populates options prop in AutocompleteInput ,,,, (either send data prop or getData) 
    getOptionLabel: same as AutocompleteInput *required
    ... whatever the AutocompleteInput takes 
  }

7. switch:
  uses CustomSwitch component

8. rich-text:
  uses RichTextInput component
  options => {
    dir: ltr/rtl (ltr by default)
    getDirection: function to determine direction. Takes watch as argument (should return ltr or rtl)
  }

9. image: 
  uses ImageUpload component

10. dropzone:
  uses DropFiles component

11. hidden:
  Does not render anything. Used for to register fields in form that are not rendered.

*/
