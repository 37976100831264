const styles = (theme) => ({
  buttonsContainer: {
    display: "flex",
    // width: 530,
    // justifyContent: "space-between",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: 15,
    },
  },
  button: { width: 120, height: 35, marginLeft: 10, marginTop: 35 },
  activateButton: { width: "100%" },
  modal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  orderItemsTitle: { fontSize: 26, fontWeight: 500, marginBottom: 20 },
  itemName: { margin: 0 },
  itemDetailsContainer: { display: "flex" },
  orderItemsContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  successContainer: {
    backgroundColor: "#F9F9F9",
    borderRadius: "8px",
    padding: "40px 20px",
    maxWidth: "400px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  successIcon: { marginRight: 10, width: 100, height: 100, marginBottom: 15 },
  successMessage: {
    fontSize: 28,
    fontWeight: "400",
    color: "#333",
    lineHeight: "32px",
    marginBottom: 24,
    textAlign: "center",
  },
});

export default styles;
