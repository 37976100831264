import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "numberOfWarranties",
        type: "number",
        label: "Number of Warranties",
        validation: yup.string().required("Required"),
      },
    ],
  },
];
