import { useState, useEffect, useMemo, useCallback } from "react";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { isEmpty } from "utils/index";
import enums from "enums/index";

//-------------------------------------------------------------------------------------

export const useScanningWarrantiesView = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [orderWarrantyLink, setOrderWarrantyLink] = useState();
  const [orderId, setOrderId] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [currentPendingItemIdx, setCurrentPendingItemIdx] = useState();
  const [warrantyLinkToken, setWarrantyLinkToken] = useState();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [activeScreen, setActiveScreen] = useState("question");
  const [isLinkingCompleted, setIsLinkingCompleted] = useState(false);

  const [sendRequest] = useHttpClient();
  const [sendGetOrderItemsRequest] = useHttpClient();
  const [sendLinkWarrantyRequest] = useHttpClient();
  const [sendUnlinkWarrantyRequest] = useHttpClient();
  const [
    sendActivateWarrantiesRequest,
    activateWarrantiesLoading,
  ] = useHttpClient();
  const [sendCompleteWarrantiesLinkingRequest] = useHttpClient();

  const openModal = (index) => () => {
    setIsOpen(true);
    setCurrentPendingItemIdx(index);
  };

  const onClose = () => setIsOpen(false);

  const canActivate = useMemo(
    () =>
      orderItems?.some((item) => item.status === enums.OrderItemStatus.LINKED),
    [orderItems]
  );

  const hasPendingItems = useMemo(
    () =>
      orderItems?.some((item) => item.status === enums.OrderItemStatus.PENDING),
    [orderItems]
  );

  const getCurrentPendingItemIdx = useCallback((items) => {
    return items?.findIndex(
      (item) => item.status === enums.OrderItemStatus.PENDING
    );
  }, []);

  useEffect(() => {
    if (orderItems.length === 0) return;
    const index = getCurrentPendingItemIdx(orderItems);
    setCurrentPendingItemIdx(index);
  }, [orderItems]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    if (!token) return;

    setWarrantyLinkToken(token);

    const fetchOrderWarrantyLink = async () => {
      const response = await sendRequest(
        api.orderWarrantiesLinks.getOneByAttribute({ token })
      );

      setOrderWarrantyLink(response);

      const expirationDate = new Date(response.expirationDate);
      const now = new Date();
      if (expirationDate < now) {
        setError("This session is either expired or does not exist");
        return;
      }

      const res = await sendGetOrderItemsRequest(
        api.orders.getItems(response?.orderId)
      );

      try {
        await api.orderWarrantiesLinks.activate(token);

        if (res?.items?.length === 0) return;

        setOrderId(res.items[0].orderId);

        setOrderItems(
          res.items.flatMap((orderItem, itemIndex) =>
            Array.from(
              { length: orderItem.amount - orderItem.numberOfLinkedItems },
              (_, index) => ({
                id: orderItem?.id,
                name: orderItem?.item?.name,
                itemId: orderItem?.item?.id,
                index: itemIndex * orderItem.amount + index,
                serial: orderItem?.item?.serial,
                price: orderItem.unitPrice,
                status: enums.OrderItemStatus.PENDING,
              })
            )
          )
        );
      } catch (err) {}
    };

    fetchOrderWarrantyLink();
  }, []);

  const onItemScan = async (publicKey) => {
    try {
      const response = await sendLinkWarrantyRequest(
        api.warranties.linkWarranty({
          orderItemId: orderItems[currentPendingItemIdx].id,
          warrantyPublicKey: publicKey,
          warrantyLinkToken: orderWarrantyLink?.token,
        })
      );

      setOrderItems((prevItems) => {
        const updatedItems = [...prevItems];
        updatedItems[currentPendingItemIdx].status =
          enums.OrderItemStatus.LINKED;
        updatedItems[currentPendingItemIdx].publicKey = publicKey;
        const index = getCurrentPendingItemIdx(updatedItems);
        setCurrentPendingItemIdx(index);
        return updatedItems;
      });
    } catch (err) {
      const error =
        err?.response?.data?.message ||
        "An error has occurred, please try again later";
      toast.error(error);
    } finally {
      onClose();
    }
  };

  const onItemSkip = async () => {
    setOrderItems((prevItems) => {
      const updatedItems = [...prevItems];

      updatedItems[currentPendingItemIdx].status =
        enums.OrderItemStatus.SKIPPED;
      const index = getCurrentPendingItemIdx(updatedItems);
      setCurrentPendingItemIdx(index);
      return updatedItems;
    });
  };

  const onItemUnlink = (index) => async () => {
    try {
      await sendUnlinkWarrantyRequest(
        api.warranties.unlinkWarranty({
          orderItemId: orderItems[index].id,
          warrantyPublicKey: orderItems[index].publicKey,
        })
      );
    } catch (err) {}

    setOrderItems((prevItems) => {
      const updatedItems = [...prevItems];
      updatedItems[index].status = enums.OrderItemStatus.PENDING;
      updatedItems[index].publicKey = null;
      return updatedItems;
    });
  };

  const activateWarranties = async () => {
    try {
      const items = orderItems.flatMap((record) =>
        !isEmpty(record.publicKey)
          ? {
              id: record.id,
              itemId: record.itemId,
              warrantyPublicKey: record.publicKey,
            }
          : []
      );

      await sendActivateWarrantiesRequest(
        api.warranties.activateWarranties({ orderItems: items })
      );

      await sendCompleteWarrantiesLinkingRequest(
        api.orderWarrantiesLinks.complete(warrantyLinkToken)
      );

      setActiveScreen("success");
    } catch (err) {
      const error =
        err?.response?.data?.message ||
        "An error has occurred, please try again later!";
      toast.error(error);
    }
  };

  const handleActivateWarrantiesSuccess = () => {
    setIsConfirmModalOpen(false);
    setIsLinkingCompleted(true);
  };

  return {
    isOpen,
    orderItems,
    canActivate,
    hasPendingItems,
    activeScreen,
    isConfirmModalOpen,
    error,
    orderId,
    currentPendingItemIdx,
    activateWarrantiesLoading,
    isLinkingCompleted,
    setIsConfirmModalOpen,
    activateWarranties,
    onItemScan,
    onItemUnlink,
    onItemSkip,
    openModal,
    onClose,
    handleActivateWarrantiesSuccess,
  };
};
