import { makeStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import routes from "routes";
import { flatten } from "utils";
import styles from "./breadcrumbsStyle";

const useStyles = makeStyles(styles);

const injectIds = (matchedPath, currentPath) => {
  return matchedPath
    ?.split("/")
    ?.map((_, index) => currentPath?.split("/")[index])
    ?.join("/");
};

const Breadcrumbs = () => {
  const classes = useStyles();

  const [crumbs, setCrumbs] = React.useState([]);
  const location = useLocation();

  const { t } = useTranslation();

  React.useEffect(() => {
    try {
      const paths = flatten(getPaths(routes, 0)).filter(function (el) {
        return el != null;
      });

      let currentPath = location.pathname;
      let pathParams = {};

      const tempPathArr = currentPath.split("/").map((el, index) => {
        if (isNaN(el) || index === 0) return el;
        pathParams[index] = { name: currentPath.split("/")[index - 1], id: el };
        return ":id";
      });
      currentPath = tempPathArr.join("/");

      const tempCrumbs = paths.flatMap((pathObj) => {
        if (currentPath.includes(pathObj.path)) {
          pathObj.path = injectIds(pathObj.path, location.pathname);
          const id = pathObj.path.split("/").reverse()[0];
          if (!isNaN(id)) {
            pathObj.id = id;
            if (pathObj.getName) pathObj.isLoading = true;
          }
          return [pathObj];
        } else return [];
      });

      tempCrumbs.sort((a, b) => a.depth - b.depth);
      setCrumbs(tempCrumbs.slice());

      (async () => {
        const data = await getCrumbNames(tempCrumbs);
        setCrumbs(data);
      })();
    } catch {}
  }, [location.pathname]);

  const getPaths = React.useCallback((routes, depth) => {
    return routes.map((route) => {
      if (route.collapse) {
        return getPaths(route.views, depth + 1);
      }
      let childrenRoutes = [];
      if (route.children) {
        childrenRoutes = getPaths(route.views, depth + 1);
      }
      if (route.layout === "/admin") {
        return [
          ...childrenRoutes,
          {
            name: t(route.name),
            getName: route.getName,
            path: route.layout + route.path,
            depth,
          },
        ];
      } else {
        return null;
      }
    });
  }, []);

  const getCrumbNames = React.useCallback(async (crumbs) => {
    return Promise.all(
      crumbs.map(async (crumb) => {
        if (crumb.getName) {
          crumb.name = await crumb.getName(crumb.id);
          crumb.isLoading = false;
        }
        return crumb;
      })
    );
  }, []);

  return (
    <div className={classes.root}>
      {crumbs.map(({ name, path, isLoading }, key) => {
        return isLoading ? (
          <div key={key} className={classes.loadingContainer}>
            <Skeleton variant="rect" height={16} width={120} />
            {key + 1 === crumbs.length ? null : (
              <ArrowForwardIosIcon className={classes.icon} />
            )}
          </div>
        ) : key + 1 === crumbs.length ? (
          <span key={key}>{name}</span>
        ) : (
          <Link key={key} className={classes.link} to={path}>
            {name}
            <ArrowForwardIosIcon className={classes.icon} />
          </Link>
        );
      })}
    </div>
  );
};
export default Breadcrumbs;
