import enums from "enums/index";

export default {
  resource: {
    [enums.Resource.ACCESS_MANAGEMENT]: "Access Management",
    [enums.Resource.WARRANTIES]: "Warranties",
    [enums.Resource.ORDERS_MANAGEMENT]: "Orders Management",
    [enums.Resource.ITEMS_MANAGEMENT]: "Items Management",
    [enums.Resource.RETAILERS]: "Retailers",
  },
  roleCode: {
    [enums.RoleCode.SUPER_ADMIN]: "Super Admin",
    [enums.RoleCode.OPS_MANAGER]: "Operations Manager",
  },
  orderStatus: {
    [enums.OrderStatus.LEAD]: "Lead",
    [enums.OrderStatus.PLANNED]: "Planned",
    [enums.OrderStatus.ASSIGNED]: "Assigned",
    [enums.OrderStatus.IN_PROGRESS]: "In Progress",
    [enums.OrderStatus.COMPLETED]: "Completed",
    [enums.OrderStatus.CANCELLED]: "Cancelled",
    [enums.OrderStatus.LOST]: "Lost",
  },
  itemType: {
    [enums.ItemType.NEW]: "New",
    [enums.ItemType.SCRAP]: "Scrap",
  },
  itemGroup: {
    [enums.ItemGroup.BATTERIES]: "Batteries",
    [enums.ItemGroup.WB]: "WB",
    [enums.ItemGroup.TOOLS_AND_ACCESSORIES]: "Tools and Accessories",
  },
  anErrorHasOccurred: "An error has occurred",
};
