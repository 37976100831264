import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import { deepen, flattenObject } from "utils/index";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";
import ItemValidation from "./OrderItem.validation";
import enums from "enums/index";
import { useEffect } from "react";

//-------------------------------------------------------------------------------------

export default function useOrderItemModal({ itemProp, onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { order, getLatestOrder } = React.useContext(OrderContext);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(ItemValidation());

  const carBatterySize = order?.car?.batterySize.size;

  const [promoCodeDisabled, setPromoCodeDisabled] = React.useState(false);

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });
  const { handleSubmit, getValues, setValue, watch } = form;
  const promoCode = watch("promoCode.code");
  const promoCodeId = watch("promoCode.id");
  const promoCodeObj = watch("promoCode");

  const [
    sendRemainingAmountRequest,
    isRemainingAmountRequestLoading,
  ] = useHttpClient(true);
  const [remainingAmount, setRemainingAmount] = React.useState(null);
  const productId = form.watch("product")?.id;

  React.useEffect(() => {
    if (productId) {
      (async () => {
        try {
          const response = await sendRemainingAmountRequest(
            api.products.getProductAvailability(productId, order.id)
          );

          setRemainingAmount(response);
        } catch (err) {
          toast.error("An error has occurred");
        }
      })();
    }
  }, [productId]);

  const [sendCheckPromoCodeRequest, isCheckPromoCodeLoading] = useHttpClient(
    true
  );

  useEffect(() => {
    setPromoCodeDisabled(!!promoCodeId);
  }, [promoCodeId]);

  const removePromoCode = () => {
    setValue("promoCode", { code: "" });
  };

  const checkAndApplyPromoCode = async () => {
    if (!promoCode) {
      toast.error("Please enter a promo code");
      return;
    }
    try {
      const response = await sendCheckPromoCodeRequest(
        api.promoCodes.check(promoCode)
      );
      if (response) {
        toast.success(`Promo code has been applied successfully`);
        setValue("promoCode", response);
        setPromoCodeDisabled(true);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    if (itemProp) {
      const formData = {
        ...flattenObject(itemProp),
        orderId: order.id,
      };

      Object.keys(formData).forEach((key) => {
        setValue(key, formData[key]);
      });
    } else {
      setValue("orderId", order.id);
    }
  }, [itemProp, order.id]);

  const createHandler = (values) => async () => {
    try {
      await sendRequest(api.orderItems.create(deepen(values)));
      toast.success(`Order item has been added successfully`);
      getLatestOrder();
      onClose();
    } catch {}
  };

  const editHandler = (values) => async () => {
    try {
      await sendRequest(api.orderItems.patch(deepen(values)));
      toast.success(`Order item has been updated successfully`);
      getLatestOrder();
      onClose();
    } catch {}
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    let values = getValues();
    values = {
      ...values,
      productId: values.product?.id,
      promoCodeId: values.promoCode?.id ?? null,
      orderId: order.id,
      unitPrice: values.product?.unitPrice || 0,
      type: enums.ProductType.NEW,
    };
    delete values.product;
    if (!values.promoCodeId) delete values.promoCode;

    const checkStockAvailability = await sendRequest(
      api.orders.checkStockAvailability(
        [{ productId: values.productId, amount: values.amount }],
        order.id
      )
    );

    handleSubmit(
      !!itemProp ? editHandler(values) : createHandler(values),
      onError
    )();
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    carBatterySize,
    promoCodeDisabled,
    isCheckPromoCodeLoading,
    submitHandler,
    onError,
    checkAndApplyPromoCode,
    removePromoCode,
    remainingAmount,
    isRemainingAmountRequestLoading,
    productId,
  };
}
