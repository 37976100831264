import React from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import AddStockValidation from "./AddStockModal.validation";

//-------------------------------------------------------------------------------------

export default function useAddStockModal({ onClose, successCallback }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(AddStockValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
    defaultValues: {
      amount: 1,
    },
  });

  const { handleSubmit, getValues } = form;
  const submitHandler = async () => {
    let values = getValues();

    values = {
      ...values,
      itemId: values.item?.id,
    };

    delete values.item;
    try {
      const newStock = await sendRequest(api.stockItems.createMany(values));
      onClose();

      successCallback(newStock);
      toast.success(`Stock added successfully`);
    } catch (err) {
      console.log(err);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
