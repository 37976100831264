import AutocompleteInput from "components/AutocompleteInput/AutocompleteInput";
import CustomInput from "components/CustomInput/CustomInput";
import SelectInput from "components/SelectInput/SelectInput";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./inputNodeStyles";

const useStyles = makeStyles(styles);

export default function InputNode({
  type,
  error,
  data,
  multiple,
  options,
  onChange,
  value,
  label,
}) {
  const classes = useStyles();

  return type === "text" ||
    type === "number" ||
    type === "date" ||
    type === "month" ||
    type === "datetime-local" ? (
    <CustomInput
      formControlProps={{
        fullWidth: true,
        className: classes.noPadding + " " + classes.noMargin,
      }}
      inputProps={{
        onChange,
        value:
          type === "date"
            ? value?.split("T")[0]
            : type === "month"
            ? value?.split("T")[0]?.substring(0, 7)
            : value,
        type,
      }}
      error={error}
    />
  ) : type === "boolean" ? (
    <SelectInput
      selectStyle={classes.noPadding + " " + classes.noMargin}
      placeholder={label}
      error={error}
      data={[
        { name: "Yes", value: true },
        { name: "No", value: false },
      ]}
      selectedValue={value}
      onSelect={onChange}
    />
  ) : type === "select" ? (
    <SelectInput
      placeholder={label}
      error={error}
      data={data}
      selectedValue={value}
      onSelect={onChange}
    />
  ) : type === "autocomplete" ? (
    <AutocompleteInput
      getOptionDisabled={
        multiple
          ? (option) =>
              value?.some((selectedEl) => {
                if (typeof option === "object")
                  return selectedEl.id === option.id;
                else return selectedEl === option;
              })
          : undefined
      }
      formControlProps={{ fullWidth: true }}
      options={data}
      labelText={label}
      selectedValue={value}
      onChange={(_, newValue) => {
        const event = { target: { value: newValue } };
        onChange(event);
      }}
      error={error}
      {...options}
    />
  ) : null;
}
