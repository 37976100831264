import { primaryColor } from "assets/jss/material-dashboard-pro-react.js";
const styles = (theme) => ({
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  infoContainer: {
    padding: "0px 20px",
    marginBottom: -20,
  },
  icon: {
    marginRight: 15,
    fontSize: 20,
    color: "#6f7f88",
  },
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    maxWidth: 500,
  },
  radioRoot: {
    justifyContent: "flex-start",
    marginLeft: 20,
    marginBottom: -20,
  },
  radioLabel: {
    marginRight: 20,
  },
  radioButtonLabel: {
    marginLeft: -10,
    marginRight: 10,
  },
  radioButtonsContainer: {
    flexDirection: "row",
  },
  radioButton: {
    marginRight: 5,
  },
  addButton: {
    padding: "5px 15px",
    zIndex: 20,
    display: "flex",
    alignItems: "center",
    marginRight: 10,
  },
  loadingContainer: {
    display: "flex",
    alignItems: "center",
    color: primaryColor[0],
    fontWeight: 500,
    marginTop: 25,
    marginBottom: 15,
  },
});

export default styles;
