// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import login from "assets/img/login-page-background.png";
import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
// import Footer from "components/Footer/Footer.js";
import routes from "routes";

const useStyles = makeStyles(styles);

export default function Pages(props) {
  // ref for the wrapper div
  const wrapper = React.createRef();
  // styles
  const classes = useStyles();
  React.useEffect(() => {
    document.body.style.overflow = "unset";
    // Specify how to clean up after this effect:
    return function cleanup() {};
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    return login;
  };

  return (
    <div>
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/auth" to="/auth/login" />
          </Switch>
          {/* <Footer white /> */}
        </div>
      </div>
    </div>
  );
}
