import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import { hexToRgb } from "assets/jss/material-dashboard-pro-react";
import { successColor } from "assets/jss/material-dashboard-pro-react";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const infoWithLabelStyle = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20,
    fontSize: 14,
  },
  formField: {
    margin: "9px 0 17px",
  },
  label: {
    fontWeight: 500,
    color: "#999",
    marginBottom: 8,
  },
  inputLabel: {
    color: primaryColor[0],
    marginRight: 7,
  },
  value: {
    fontWeight: 500,
    whiteSpace: "pre-line",
  },
  dangerColor: {
    color: dangerColor[0],
  },
  successColor: {
    color: successColor[0],
  },
  link: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    color: primaryColor[0],
    transition: "all 0.3s",
    fontWeight: 500,
    "&:hover": {
      color: "rgba(" + hexToRgb(primaryColor[0]) + ",0.6) !important",
    },
    "&:focus,&:visited": {
      color: primaryColor[0],
      textDecoration: "none",
    },
  },
  linkIcon: {
    fontSize: 13,
    marginLeft: 5,
  },
  tagsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  tag: {
    backgroundColor: "#f5f5f5",
    color: "#777",
    padding: "3px 10px",
    borderRadius: 20,
    marginRight: 10,
    marginBottom: 10,
    fontSize: 13,
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#e5e5e5",
      // cursor: "pointer",
    },
  },
});

export default infoWithLabelStyle;
