import axios from "axios";
import { logInWithEmailAndPassword } from "./firebase";
import qs from "qs";
import { auth } from "./firebase";

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/`,
  withCredentials: true,
});

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
      return;
    }
    prom.resolve(token);
  });
  failedQueue = [];
};

// const createAxiosResponseInterceptor = async () => {
// const interceptor =
axiosInstance.interceptors.response.use(
  async (response) => response,
  async (error) => {
    /* Reject promise if usual error */
    if (!error.response || error.response?.status !== 401) {
      return Promise.reject(error);
    }
    let originalRequest = error.response.config;

    if (isRefreshing) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then((accessToken) => {
          originalRequest.headers.authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    isRefreshing = true;
    /*
     * When response code is 401, try to refresh the token.
     * Eject the interceptor so it doesn't loop in case
     * token refresh causes the 401 response
     */
    // axiosInstance.interceptors.response.eject(interceptor);
    return new Promise(async function (resolve) {
      try {
        const newAccessToken = await auth.currentUser.getIdToken(true);
        setAuthToken(newAccessToken);
        error.response.config.headers.authorization = `Bearer ${newAccessToken}`;
        isRefreshing = false;
        processQueue(null, newAccessToken);
        resolve(axiosInstance(error.response.config));
      } catch (err) {
        isRefreshing = false;
        processQueue(error, null);
        resolve({ response: { status: 401 } });
      }
      // finally {
      //   return createAxiosResponseInterceptor;
      // }
    });
  }
);
// };

// createAxiosResponseInterceptor();

const setAuthToken = async (token) => {
  axiosInstance.defaults.headers.common.authorization = `Bearer ${token}`;
};

const clearAuthToken = async () => {
  return setAuthToken("");
};

const login = (authData) => {
  return logInWithEmailAndPassword(authData.id, authData.password);
  // return axiosInstance.post("auth/user/login", authData);
};

const refreshToken = () => axiosInstance.get("/auth/user/token/refresh");

const logout = () => axiosInstance.get("auth/user/logout");

const createFormData = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key]?.length) {
      data[key].forEach((el, index) => {
        formData.append(`${key}[${index}]`, JSON.stringify(el));
      });
    } else {
      formData.append(
        key,
        typeof data[key] === "object" && data[key]?.arrayBuffer === undefined
          ? JSON.stringify(data[key])
          : data[key]
      );
    }
  });
  return formData;
};

const createDataResource = (path, isMultipart = false) => ({
  getAll: (
    page = undefined,
    limit = undefined,
    q = undefined,
    filters = undefined,
    orderBy = undefined
  ) => {
    let query = { page, limit, q, ...filters, orderBy };
    query = qs.stringify(query);
    return axiosInstance.get(`${path}?${query}`);
  },
  getOne: (id) => axiosInstance.get(`${path}/${id}`),
  getOneByAttribute: (filters = undefined) => {
    let query = filters || {};
    query = qs.stringify(filters);
    return axiosInstance.get(`${path}/single?${query}`);
  },
  create: (data) => {
    let response;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.post(path, formData, config);
    } else {
      response = axiosInstance.post(path, data);
    }
    return response;
  },
  createMany: (data) => axiosInstance.post(`${path}/bulk`, data),
  patch: (data) => {
    let response;
    const id = data.id;
    if (isMultipart) {
      const formData = createFormData(data);
      const config = { headers: { "Content-Type": "multipart/form-data" } };
      response = axiosInstance.patch(`${path}/${id}`, formData, config);
    } else {
      response = axiosInstance.patch(`${path}/${id}`, data);
    }
    return response;
  },
  delete: (id) => axiosInstance.delete(`${path}/${id}`),
  updateMany: (data) => axiosInstance.patch(path, data),
});

const uploadFile = (file) => {
  const formData = createFormData({ file });
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  return axiosInstance.post(`file-upload`, formData, config);
};

const uploadFiles = (data) => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    if (key === "files" && data[key].length > 0)
      data[key].forEach((file) => formData.append(`files`, file));
    else formData.append("files", data[key]);
  });
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return axiosInstance.post(`file-upload/bulk`, formData, config);
};

const users = {
  ...createDataResource("users"),
  fetchUserData: (firebaseID) =>
    axiosInstance.get(`users/firebase/${firebaseID}`),
};

const roles = createDataResource("roles");
const departments = createDataResource("departments");
const permissions = createDataResource("permissions");
const orderItems = createDataResource("order-items");
const retailers = createDataResource("retailers");
const salesReps = createDataResource("sales-reps");
const warranties = {
  ...createDataResource("warranties"),
  linkWarranty: (data) => axiosInstance.post(`warranties/link`, data),
  unlinkWarranty: (data) => axiosInstance.post(`warranties/unlink`, data),
  activateWarranties: (data) => axiosInstance.post(`warranties/activate`, data),
};
const orders = {
  ...createDataResource("orders"),
  getItems: (id) => axiosInstance.get(`orders/${id}/items`),
};
const warrantyBatches = {
  ...createDataResource("warranty-batches"),

  downloadWarranties: (batchId) => {
    return axiosInstance.get(
      `warranty-batches/${batchId}/warranties/download`,
      {
        responseType: "blob",
      }
    );
  },
  fetchUserData: (firebaseID) =>
    axiosInstance.get(`users/firebase/${firebaseID}`),
};
const items = createDataResource("items");
const manufacturers = createDataResource("manufacturers");
const stockItems = createDataResource("stock-items");

const orderWarrantiesLinks = {
  ...createDataResource("order-warranties-links"),
  downloadWarranties: (batchId) => {
    return axiosInstance.get(
      `warranty-batches/${batchId}/warranties/download`,
      {
        responseType: "blob",
      }
    );
  },
  getOneByOrderId: (orderId) =>
    axiosInstance.get(`order-warranties-links/order/${orderId}`),
  activate: (token) => axiosInstance.get(`order-warranties-links/activate/${token}`),
  complete: (token) => axiosInstance.get(`order-warranties-links/complete/${token}`),
};

export default {
  users,
  roles,
  departments,
  permissions,
  orders,
  orderItems,
  retailers,
  salesReps,
  warranties,
  warrantyBatches,
  orderWarrantiesLinks,
  items,
  stockItems,
  manufacturers,
  uploadFile,
  uploadFiles,
  login,
  refreshToken,
  setAuthToken,
  clearAuthToken,
  logout,
};
