const styles = (theme) => ({
  formHeader: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: 400,
    padding: "10px 30px",
    paddingBottom: 20,
    borderBottom: "1px solid #ddd",
    margin: "0px -20px",
    textTransform: "capitalize",
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  cardRoot: {
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
  },
  icon: {
    marginRight: 15,
    fontSize: 26,
    color: "#6f7f88",
  },
  modalHeader: {
    width: "100%",
    position: "absolute",
  },
  modalBody: {
    padding: 0,
    maxWidth: 500,
  },
});

export default styles;
