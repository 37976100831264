import * as yup from "yup";

//-------------------------------------------------------------------------------------

export default () => {
  return yup.object({
    name: yup.mixed().required("Required"),
    email: yup.string().email("Invalid email address").required("Required"),
    phone: yup.mixed().nullable(),
    title: yup.mixed().nullable(),
    password: yup
      .string()
      .min(8, "Password must be at least 8 characters")
      .nullable(),
  });
};
