import FormLabel from "@material-ui/core/FormLabel";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import CheckboxInput from "./CheckboxInput/CheckboxInput";
import styles from "./CheckboxGroup.style";

const useStyles = makeStyles(styles);

export default function CheckboxGroup(props) {
  const { data, label, value, onChange } = props;
  const [checked, setChecked] = React.useState(value ? value : []);
  const [isAllChecked, setIsAllChecked] = React.useState(false);

  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (!isAllChecked) {
      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }
      if (newChecked.length === data.length) {
        setIsAllChecked(true);
        setChecked([]);
        onChange([]);
      } else {
        setIsAllChecked(false);
        setChecked(newChecked);
        onChange(newChecked);
      }
    } else {
      setIsAllChecked(false);
      data.forEach((item) => {
        if (item.value !== value) {
          newChecked.push(item.value);
        }
      });
      setChecked(newChecked);
      onChange(newChecked);
    }
  };

  const handleToggleAll = () => {
    setIsAllChecked(!isAllChecked);
    setChecked([]);
    onChange([]);
  };

  const classes = useStyles();

  return (
    <GridContainer className={classes.root}>
      <GridItem xs={12} sm={4}>
        <FormLabel
          className={
            classes.labelHorizontal + " " + classes.labelHorizontalRadioCheckbox
          }
        >
          {label}:
        </FormLabel>
      </GridItem>
      <GridItem xs={12} sm={8}>
        <CheckboxInput
          value={isAllChecked || checked.length === data.length}
          onClick={() => handleToggleAll()}
          label="All"
        />
        {data.map((el) => (
          <CheckboxInput
            value={
              isAllChecked || checked.indexOf(el.value) !== -1 ? true : false
            }
            onClick={() => handleToggle(el.value)}
            label={el.name}
          />
        ))}
      </GridItem>
    </GridContainer>
  );
}
