import ListIcon from "@material-ui/icons/List";
import api from "services/api";
import React from "react";
import CreateOrderPage from "views/Orders/pages/CreateOrderPage/CreateOrderPage";
import OrderView from "views/Orders/pages/OrderView/OrderView";
import OrdersList from "views/Orders/pages/OrdersList/OrdersList";
import enums from "enums/index";

// appear in breadcrumbs and page headers
const listPageName = "Orders";
const createPageName = "Create Order";
const viewPageName = "View Order";

const layout = "/admin";

const path = "orders";

const dataResource = api.orders;

const resource = enums.Resource.ORDERS_MANAGEMENT;

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: ListIcon,
  render: () => <OrdersList />,
  action: "read",
  // resource,
  layout: layout,
  // randomKey: true,
  children: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => <CreateOrderPage path={path} />,
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
    },
    {
      path: `/${path}/:id`,
      render: () => <OrderView path={path} />,
      layout: layout,
      // randomKey: true,
      action: "read",
      getName: async (id) => {
        try {
          const data = (await dataResource.getOne(id)).data;
          return `Order #${data.orderNo}`;
        } catch {
          return viewPageName;
        }
      },
      resource,
    },
  ],
};
