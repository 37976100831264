import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import UploadOrdersModalValidation from "./UploadOrdersModal.validation";

//-------------------------------------------------------------------------------------

export default function useUploadOrdersModal({ onClose }) {
  const [sendRequest, isLoading] = useHttpClient(true);

  const { i18n } = useTranslation();

  const resolver = useYupValidationResolver(UploadOrdersModalValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, getValues, reset } = form;

  const submitHandler = async () => {
    try {
      let values = getValues();

      await sendRequest(api.orders.createMany(values));
      onClose(true);
      reset();

      toast.success(`Orders created successfully`);
    } catch (error) {
      console.error(error);
    }
  };

  const onError = () => {
    toast.error("Please fix the errors and submit again");
  };

  return {
    isLoading,
    i18n,
    form,
    submitHandler,
    onError,
    handleSubmit,
  };
}
