import Collapse from "@material-ui/core/Collapse";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import TuneIcon from "@material-ui/icons/Tune";
import classNames from "classnames";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";
import GenericInput from "components/GenericInput/GenericInput";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import _ from "lodash";
import React from "react";
import { useRouteMatch } from "react-router-dom";
import { isEmptyObject, setPageSettingsProp } from "utils";
import styles from "./Filters.styles";

const useStyles = makeStyles(styles);

export default function Filters(props) {
  const { handleSubmit, searchProps, filterProps } = props;
  const classes = useStyles();

  const [collapsed, setCollapsed] = React.useState({
    value: true,
    touched: false,
  });

  const [filtersState, setFiltersState] = React.useState({});
  const [searchState, setSearchState] = React.useState("");

  const match = useRouteMatch();

  const defaultFilters = React.useMemo(() => {
    const filters = {};
    if (filterProps?.inputs?.length) {
      filterProps.inputs.forEach(
        (input, index) =>
          (filters[input.name] =
            filterProps.inputs[index].defaultValue !== undefined
              ? filterProps.inputs[index].defaultValue
              : filterProps.inputs[index].type === "text"
              ? ""
              : null)
      );
    }
    return filters;
  }, [filterProps?.inputs]);

  React.useEffect(() => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    const isCollapsed =
      settings &&
      settings[match.path] &&
      settings[match.path].filters &&
      settings[match.path].filters.isCollapsed;

    setCollapsed(
      isCollapsed === undefined || isCollapsed === null
        ? { value: true, touched: false }
        : { value: isCollapsed, touched: false }
    );
  }, []);

  React.useEffect(() => {
    if (filterProps?.value) {
      setFiltersState(filterProps.value);
    }
    if (searchProps.value) {
      setSearchState(searchProps.value);
    }
  }, [filterProps?.value, searchProps.value]);

  React.useEffect(() => {
    if (collapsed.touched) {
      setPageSettingsProp(`${match.path}.filters.isCollapsed`, collapsed.value);
    }
  }, [collapsed.value]);

  const handleEventChange = (prop) => (event) => {
    setFiltersState({ ...filtersState, [prop]: event.target.value });
  };

  const handleMultiSelectChange = (prop) => (_, value) => {
    setFiltersState({ ...filtersState, [prop]: value });
  };

  const filterIconClasses = classNames({
    [classes.headerLinksSvg]: true,
    [classes.filterIcon]: true,
    [classes.filterIconActive]: !collapsed.value,
  });

  const handleClearFilters = () => {
    setFiltersState(defaultFilters);
    // handleSubmit(searchState, defaultFilters);
  };

  const hideClearButton = React.useMemo(() => {
    return (
      isEmptyObject(filtersState) || _.isEqual(filtersState, defaultFilters)
    );
  }, [filtersState]);

  const clearButtonClasses = classNames({
    [classes.clearButton]: true,
    [classes.hidden]: hideClearButton,
  });

  return (
    <div className={classes.root}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (filterProps) filterProps.onChange(filtersState);
          if (searchProps) searchProps.onChange(searchState);
          handleSubmit(searchState, filtersState);
        }}
        className={classes.form}
      >
        <div className={classes.nonCollapsedContainer}>
          <div className={classes.searchRoot}>
            <div className={classes.searchLabel}>What are you looking for?</div>
            <div className={classes.searchContainer}>
              <SearchIcon className={classes.searchIcon} />
              <CustomInput
                formControlProps={{
                  className: classes.top + " " + classes.search,
                  fullWidth: true,
                }}
                inputProps={{
                  placeholder: searchProps.placeholder
                    ? searchProps.placeholder
                    : "Search",
                  inputProps: {
                    "aria-label": "Search",
                    className: classes.searchInput,
                  },
                  onChange: (event) => setSearchState(event.target.value),
                  value: searchState,
                }}
                variant="none"
              />
            </div>
          </div>

          <div className={classes.buttonsContainer}>
            {filterProps?.inputs && (
              <div className={classes.filterButtonsContainer}>
                <Button
                  color="white"
                  aria-label="clear"
                  justIcon
                  round
                  className={clearButtonClasses}
                  onClick={handleClearFilters}
                  disabled={hideClearButton}
                >
                  <CloseIcon className={classes.closeIcon} />
                </Button>
                <Button
                  color={!collapsed.value ? "primary" : "white"}
                  aria-label="edit"
                  justIcon
                  round
                  className={classes.top + " " + classes.filterButton}
                  onClick={() => {
                    setCollapsed({ value: !collapsed.value, touched: true });
                  }}
                >
                  <TuneIcon className={filterIconClasses} />
                </Button>
              </div>
            )}
            <Button
              color="primary"
              aria-label="edit"
              className={classes.searchButton}
              type="submit"
            >
              {`Search ${filterProps?.inputs ? "and apply filters" : ""}`}
            </Button>
          </div>
        </div>
        <Collapse in={!collapsed.value && !!filterProps.inputs}>
          <GridContainer className={classes.collapseContainer}>
            {filterProps?.inputs?.flatMap((input, index) => {
              return !input.hidden
                ? [
                    <GridItem key={index} xs={12} sm={12} md={3}>
                      <GenericInput
                        type={input.type}
                        name={input.name}
                        label={input.label}
                        options={{ ...input.options, shrink: true }}
                        error={null}
                        value={filtersState[input.name]}
                        onChange={
                          input.type === "autocomplete"
                            ? handleMultiSelectChange(input.name)
                            : handleEventChange(input.name)
                        }
                      />
                    </GridItem>,
                  ]
                : [];
            })}
          </GridContainer>
        </Collapse>
      </form>
    </div>
  );
}
