const tabsStyle = (theme) => ({
  tabsContainer: {
    display: "flex",
    alignItems: "flex-end",
    marginTop: 30,
    padding: "0 40px",
    width: "100%",
  },
  tabButton: {
    fontSize: 16,
    fontWeight: 500,
    borderRadius: 0,
    marginBottom: 2,
    paddingBottom: 10,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      margin: "auto",
      marginTop: 15,
    },
  },
});

export default tabsStyle;
