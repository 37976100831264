import { dangerColor } from "assets/jss/material-dashboard-pro-react";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";

const style = (theme) => ({
  root: {
    backgroundColor: "white",
    borderRadius: 10,
    boxShadow: "0px 0px 10px rgba(0,0,0,0.1)",
    border: "1px solid #d7d7d7",
    marginBottom: 30,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
  },
  header: {
    padding: "10px",
    border: "none",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  nonCollapsedContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: "10px 20px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  searchRoot: {
    display: "fleX",
    flexDirection: "column",
    marginBottom: 10,
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: 5,
    border: "1px solid #f7f7f7",
    backgroundColor: "#F9FAFF",
    padding: "3px 8px",
    height: "fit-content",
  },
  searchLabel: {
    fontWeight: 500,
    fontSize: 14,
    color: "black",
    marginBottom: 5,
  },
  search: {
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    "&:focus": {
      outline: "none",
    },
    "& > div.MuiInput-root": {
      width: "100%",
    },
    "& > div > input": {
      padding: 0,
      height: 25,
    },
  },
  searchIcon: {
    width: "20px",
    height: "20px",
    marginRight: "5px",
    color: "#ccc",
  },
  searchInput: {
    width: "100%",
    height: "100%",
    padding: 0,
    border: "none",
    backgroundColor: "transparent",
    color: "black",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "1.42857",
    letterSpacing: "unset",
    "&::placeholder": {
      color: "black",
    },
  },
  buttonsContainer: {
    marginLeft: "auto",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      margin: "unset",
      justifyContent: "center",
      padding: "0px 10px",
      width: "100%",
      // flexDirection: "column",
    },
  },
  searchButton: {
    height: "fit-content",
    padding: "7px 40px",
    borderRadius: 5,
    [theme.breakpoints.down("md")]: {
      minWidth: "unset",
      width: "60%",
      padding: "7px 10px",
      order: 0,
    },
  },
  filterButtonsContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-end",
      padding: "0px 10px",
      width: "100%",
      order: 1,
    },
  },
  clearButton: {
    marginRight: 10,
    padding: "10px !important",
    height: 35,
    width: 35,
    minWidth: "unset",
    opacity: 1,
    transition: "all 0.2s",
  },
  hidden: {
    opacity: 0,
  },
  closeIcon: {
    zIndex: "4",
    color: dangerColor[0],
    transition: "all 0.3s",
    width: "20px !important",
    height: "20px !important",
  },
  filterButton: {
    marginRight: 20,
    padding: "10px !important",
    height: 35,
    width: 35,
    minWidth: "unset",
    [theme.breakpoints.down("sm")]: {
      //   top: "-50px !important",
      marginRight: "0px",
    },
  },
  filterIconActive: {
    color: "white !important",
  },
  filterIcon: {
    width: "16px",
    zIndex: "4",
    color: primaryColor[0],
    transition: "all 0.3s",
  },
  headerLinksSvg: {
    width: "20px !important",
    height: "20px !important",
  },
  collapseContainer: {
    backgroundColor: `${primaryColor[0]}10`,
    padding: "10px 20px",
  },
});

export default style;
