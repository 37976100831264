const styles = (theme) => ({
  noPadding: {
    padding: "0px !important",
  },
  noMargin: {
    margin: "0px !important",
  },
});

export default styles;
