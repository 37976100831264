import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    let mainPanel = document.getElementById("main-panel");
    if (mainPanel) mainPanel.scrollTop = 0;
  }, [pathname]);

  return null;
}
