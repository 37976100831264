import enums from "enums/index";
import React, { useEffect, useState } from "react";
import { OrderContext } from "views/Orders/pages/OrderView/Order.context";

//-------------------------------------------------------------------------------------

export default function useOrderItems({ form, orderProp }) {
  const [isAssignModalOpen, setIsAssignModalOpen] = React.useState(false);
  const [editedItem, setEditedItem] = React.useState(null);

  const { order: orderContext, isGetOrderLoading } = React.useContext(
    OrderContext
  );
  const [orderItems, setOrderItems] = useState([]);

  const order = React.useMemo(() => orderProp || orderContext, [
    orderProp,
    orderContext,
  ]);

  useEffect(() => {
    setOrderItems(
      order.items?.length
        ? order.items.flatMap((item) => {
            if (item.type === enums.ProductType.NEW) {
              return { ...item, key: Math.random() };
            } else return [];
          })
        : []
    );
  }, [order]);

  const { setValue } = form;

  const handleAssignItem = React.useCallback(
    (data) => {
      setEditedItem(data);
      setIsAssignModalOpen(true);
    },
    [setIsAssignModalOpen, setEditedItem]
  );

  const onAssignModalClose = React.useCallback(() => {
    setIsAssignModalOpen(false);
    setEditedItem(null);
  }, [setIsAssignModalOpen, setEditedItem]);

  const onRemoveModalClose = React.useCallback(
    (record) => {
      setOrderItems((prevItems) => {
        const newItems = prevItems.filter((item) => item.key !== record.key);
        setValue("items", newItems);
        return newItems;
      });
    },
    [orderItems]
  );

  const onAdd = React.useCallback(
    (data) => {
      setOrderItems((prevItems) => {
        const newItems = [...prevItems, { ...data, key: Math.random() }];
        setValue("items", newItems);
        return newItems;
      });
    },
    [orderItems]
  );

  const onEdit = React.useCallback(
    (data) => {
      setOrderItems((prevItems) => {
        const newItems = prevItems.map((item) => {
          if (item.key === data.key) {
            return data;
          }
          return item;
        });
        setValue("items", newItems);
        return newItems;
      });
    },
    [orderItems]
  );

  return {
    order,
    isAssignModalOpen,
    isGetOrderLoading,
    editedItem,
    orderItems,
    onAssignModalClose,
    handleAssignItem,
    onRemoveModalClose,
    onAdd,
    onEdit,
  };
}
