import { Form } from "antd";
import React from "react";
import InputNode from "../InputNode/InputNode";
import Highlighter from "react-highlight-words";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./EditableCell.styles";

const useStyles = makeStyles(styles);

export default function EditableCell({
  editing,
  canBeHighlighted,
  highlightedText,
  dataIndex,
  getData,
  record,
  index,
  children,
  inputProps,
  form,
  label,
  id,
  isLoading,
  ...restProps
}) {
  const classes = useStyles();

  const validationRules = inputProps?.isRequired
    ? { rules: [{ required: true, message: `This field is required` }] }
    : {};

  return (
    <td {...restProps}>
      {editing ? (
        !isLoading && (
          <Form.Item
            key={`${id}_${index}_${dataIndex}`}
            name={[index, dataIndex]}
            style={{
              margin: 0,
            }}
            {...validationRules}
          >
            <InputNode
              error={!!form.getFieldError([0, dataIndex])[0]}
              label={label}
              {...inputProps}
            />
          </Form.Item>
        )
      ) : canBeHighlighted ? (
        <Highlighter
          highlightClassName={classes.highlightedText}
          searchWords={[highlightedText]}
          autoEscape={true}
          textToHighlight={children?.length ? children[1]?.toString() : ""}
        />
      ) : getData ? (
        children?.length ? (
          getData(children[1])
        ) : undefined
      ) : typeof children[1] === "boolean" ? (
        children[1] ? (
          "Yes"
        ) : (
          "No"
        )
      ) : (
        children
      )}
    </td>
  );
}
