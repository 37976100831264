import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "name",
        type: "text",
        label: "Name",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "phonePrimary",
        type: "text",
        label: "Phone Primary",
        validation: yup.mixed().required("Required"),
      },
      {
        name: "phoneSecondary",
        type: "text",
        label: "Phone Secondary",
      },
      {
        name: "email",
        type: "text",
        label: "Email",
      },
    ],
  },
];
