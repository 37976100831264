import commonAR from "./ar/common";
import commonEN from "./en/common";

export default {
  ar: {
    ...commonAR,
  },
  en: {
    ...commonEN,
  },
};
