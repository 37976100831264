import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//------------------------------------------------------------------

export const useWarrantyBatchView = (props) => {
  const history = useHistory();

  const [listData, setListData] = useState({ count: 0, rows: [] });
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [highlightedText, setHighlightedText] = useState("");
  const [currentTab, setCurrentTab] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);

  const [batch, setBatch] = useState(null);

  const [sendRequest, isLoading] = useHttpClient();
  const [sendBatchRequest, isBatchLoading] = useHttpClient();
  const [sendDeleteRequest, isDeleteLoading] = useHttpClient();

  useEffect(() => {
    (async () => {
      try {
        const id = window.location.pathname.split("/").pop().trim();
        const idValid = id && !isNaN(id);
        if (!idValid) return;
        const response = await sendBatchRequest(api.warrantyBatches.getOne(id));
        setBatch(response);
      } catch (err) {}
    })();
  }, []);

  useEffect(() => {
    let tab = currentTab;
    if (tab === undefined) {
      tab = new URLSearchParams(window.location.search).get("tab");
      setCurrentTab(tab);
    }

    (async () => {
      if (!batch?.id) return;
      const rowsPerPage = 10;
      setItemsPerPage(rowsPerPage);

      let { page, search, filters } = qs.parse(
        window.location.search?.split("?")[1]
      );

      setSearchQuery(search || "");
      setHighlightedText(search || "");

      if (page) {
        setCurrentPage(parseInt(page));
        await getData(page, rowsPerPage, search);
      } else {
        page = 1;
        const query = qs.stringify({ tab: currentTab, page });
        history.push({
          pathname: window.location.pathname,
          search: `?${query}`,
        });
        await getData(page, rowsPerPage, search);
      }
    })();
  }, [batch]);

  useEffect(() => {
    if (listData.count > 0 && !listData.rows.length) {
      const lastPage = Math.ceil(listData.count / itemsPerPage);
      const query = qs.stringify({
        currentTab,
        search: searchQuery,
        page: lastPage,
      });
      history.push({
        pathname: window.location.pathname,
        search: `?${query}`,
      });
      (async () => await getData(lastPage, itemsPerPage, searchQuery))();
    }
  }, [listData]);

  const onSearchChange = (updatedSearch) => {
    setSearchQuery(updatedSearch);
  };

  const getData = useCallback(
    async (
      page = currentPage,
      rowsPerPage = rowsPerPage,
      search = searchQuery
    ) => {
      page = Math.max(page, 1);
      try {
        const response = await sendRequest(
          api.warranties.getAll(page, rowsPerPage, search, {
            batchId: batch?.id,
          })
        );
        console.log("response", response.data);
        setListData({ count: response.count, rows: response.data });
      } catch (err) {
        toast.error("An error has occurred");
      }
    },
    [sendRequest, setListData]
  );

  const handleChange = async (pagination) => {
    setCurrentPage(pagination.current);
    setItemsPerPage(pagination.pageSize);
    const query = qs.stringify({
      currentTab,
      search: searchQuery,
      page: pagination.current,
    });
    history.push({
      pathname: window.location.pathname,
      search: `?${query}`,
    });
    await getData(pagination.current, pagination.pageSize, searchQuery);
  };

  const handleSubmit = async (search) => {
    setHighlightedText(search);
    const query = qs.stringify({ tab: currentTab, search, page: 1 });
    history.push({ pathname: window.location.pathname, search: `?${query}` });
    await getData(1, itemsPerPage, search);
    setCurrentPage(1);
  };

  const handleDelete = useCallback(async () => {
    try {
      await sendDeleteRequest(api.warrantyBatches.delete(batch?.id));
    } catch (err) {
      toast.error("An error has occurred");
    }
  }, [batch]);

  const handleDeleteSuccess = () =>
    history.replace({
      pathname: "/admin/warranties",
    });

  const downloadBatchFile = async () => {
    setIsDownloading(true);
    try {
      const response = await api.warrantyBatches.downloadWarranties(batch?.id);

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      console.log("response.headers", response.headers);
      const contentDisposition = response.headers["content-disposition"];
      console.log("contentDisposition", contentDisposition);
      const filename = contentDisposition.split("filename=")[1];
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
    } finally {
      setIsDownloading(false);
    }
  };

  const handleTabChange = (newTab) => {
    setCurrentTab(newTab);

    const { tab } = qs.parse(window.location.search?.split("?")[1]);

    if (newTab != tab) {
      history.replace({
        pathname: window.location.pathname,
        search: `?tab=${newTab}`,
      });
    }
  };

  return {
    isLoading,
    isDeleteLoading,
    isDownloading,
    batch,
    currentTab,
    isBatchLoading,
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    onSearchChange,
    handleSubmit,
    handleChange,
    handleTabChange,
    handleDelete,
    handleDeleteSuccess,
    downloadBatchFile,
  };
};
