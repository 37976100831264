import { makeStyles } from "@material-ui/core/styles";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { getDateString } from "utils/index";
import useOrdersList from "./OrdersList.hook";
import styles from "./OrdersList.styles";
import api from "services/api";
import UploadOrdersModal from "./components/UploadOrdersModal/UploadOrdersModal";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrdersList(props) {
  const classes = useStyles();

  const { i18n } = useTranslation();

  const { resource } = props;

  const filterInputs = useMemo(
    () => [
      {
        name: "status",
        label: "Status",
        type: "select",
        options: {
          data: Object.keys(translations[i18n.language].orderStatus).map(
            (key) => ({
              value: key,
              name: translations["en"].orderStatus[key],
            })
          ),
          addAllChoice: true,
        },
      },
      {
        name: "createdAt.from",
        label: "Created At (from)",
        type: "date",
      },
      {
        name: "createdAt.to",
        label: "Created At (to)",
        type: "date",
      },
      {
        name: "createdByIds",
        label: "Created By",
        type: "autocomplete",
        options: {
          getData: api.salesReps.getAll,
          getOptionLabel: (option) => option?.name,
          getSubmitValue: (option) => option?.id,
          blurOnSelect: false,
          loadOnFocus: true,
          multiple: true,
        },
      },
    ],
    []
  );

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isUploadOrderModalOpen,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    updateOrderState,
    handleUploadModalClose,
    setIsUploadOrderModalOpen,
  } = useOrdersList({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "Order No.",
        dataIndex: "id",
        width: 110,
        canBeHighlighted: true,
        render: (data) => `#${data}`,
      },
      {
        title: "Retailer Name",
        dataIndex: ["retailer", "name"],
        width: 150,
      },
      {
        title: "Retailer Phone",
        dataIndex: ["retailer", "phonePrimary"],
        width: 150,
      },
      {
        title: "Payment Method",
        dataIndex: "paymentMethod",
        width: 150,
      },
      {
        title: "Status",
        dataIndex: "status",
        getData: (data) => {
          if (data) return translations["en"].orderStatus[data];
          else return "-";
        },
        width: 100,
      },
      {
        title: "Created By",
        dataIndex: ["createdBy", "name"],
        width: 150,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        getData: (data) => (data ? getDateString(data, "en-EG", true) : "-"),
        width: 200,
      },
      {
        title: "",
        render: (_, record) => {
          return (
            <Link to={`/admin/orders/${record?.id}`}>
              <Button justIcon next color="white" textColor="primary" />
            </Link>
          );
        },
      },
    ],
    [listData, updateOrderState]
  );

  return (
    <div>
      <UploadOrdersModal
        isOpen={isUploadOrderModalOpen}
        onClose={handleUploadModalClose}
      />
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            upload
            color="white"
            onClick={() => setIsUploadOrderModalOpen(true)}
          >
            Upload Orders
          </Button>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Orders Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

OrdersList.propTypes = {
  resource: PropTypes.string,
};
