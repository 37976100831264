import enums from "enums/index";
import React from "react";
import Items from "views/ItemsManagement/Items/Items";

// appear in breadcrumbs and page headers
const listPageName = "Items List";

const layout = "/admin";

const path = "items";

const resource = enums.Resource.ITEMS_MANAGEMENT;

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "list",
  render: () => <Items />,
  action: "read",
  layout: layout,
  resource,
};
