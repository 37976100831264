import { makeStyles } from "@material-ui/core/styles";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import FormatAlignJustifyIcon from "@material-ui/icons/FormatAlignJustify";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import FormatAlignRightIcon from "@material-ui/icons/FormatAlignRight";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import styles from "assets/jss/material-dashboard-pro-react/components/richTextInputStyle";
// nodejs library that concatenates classes
import classNames from "classnames";
// import ReactDOM from "react-dom";
import MUIRichTextEditor from "mui-rte";
import React from "react";

const useStyles = makeStyles(styles);

const AlignText = (props) => {
  return (
    <div
      style={{
        textAlign: props.align,
      }}
    >
      {props.children}
    </div>
  );
};

const RichTextInput = ({
  value,
  setValue,
  rootStyle,
  rootClass,
  dir,
  ...rest
}) => {
  const classes = useStyles();

  const rootClasses = classNames({
    [classes.root]: true,
    [rootClass]: rootClass !== undefined,
  });

  const ref = React.useRef(null);

  const save = (data) => {
    setValue(data);
  };

  return (
    <div
      style={{ ...rootStyle }}
      className={rootClasses}
      dir={dir ? dir : "ltr"}
    >
      <MUIRichTextEditor
        ref={ref}
        label="Type something here..."
        controls={[
          "heading",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "align-left",
          "align-center",
          "align-right",
          "align-justify",
          "highlight",
          "undo",
          "redo",
          "link",
          "numberList",
          "bulletList",
          "quote",
        ]}
        customControls={[
          {
            name: "align-left",
            icon: <FormatAlignLeftIcon />,
            type: "block",
            blockWrapper: <AlignText align="left" />,
          },
          {
            name: "align-center",
            icon: <FormatAlignCenterIcon />,
            type: "block",
            blockWrapper: <AlignText align="center" />,
          },
          {
            name: "align-right",
            icon: <FormatAlignRightIcon />,
            type: "block",
            blockWrapper: <AlignText align="right" />,
          },
          {
            name: "align-justify",
            icon: <FormatAlignJustifyIcon />,
            type: "block",
            blockWrapper: <AlignText align="justify" />,
          },
          {
            name: "heading",
            icon: <ZoomInIcon />,
            type: "inline",
            inlineStyle: {
              fontSize: "1.4rem",
            },
          },
        ]}
        onSave={save}
        onBlur={() => {
          ref.current.save();
        }}
        inlineToolbar={false}
        value={value === "undefined" || value === "null" ? null : value}
        toolbar={true}
        {...rest}
      />
    </div>
  );
};

export default RichTextInput;
