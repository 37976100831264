import { makeStyles } from "@material-ui/core/styles";
import { Form, Table } from "antd";
import Button from "components/CustomButtons/Button.js";
import React, { useState, useContext } from "react";
import styles from "./customTableStyle";
import EditableCell from "./EditableCell/EditableCell";
import Filters from "./Filters/Filters";
import classNames from "classnames";
import { deepen, isEmpty } from "utils";
import { AuthContext } from "shared/context/auth-context";
import _ from "lodash";
import { accessObjectByString } from "utils/index";

const useStyles = makeStyles(styles);

export default function CustomTable(props) {
  const {
    columns,
    addIndexColumn,
    data,
    pagination,
    onChange,
    loading,
    editHandler,
    isEditLoading,
    editable,
    highlightedText,
    rowSelection,
    rowSelectionType = "checkbox",
    title,
    filterProps,
    searchProps,
    handleSubmit,
    raised,
    size,
    fixIndexColumn,
  } = props;

  const [form] = Form.useForm();
  const [dataState, setDataState] = useState(data);
  const auth = useContext(AuthContext);

  const classes = useStyles();

  React.useEffect(() => {
    form.resetFields();

    let newData = [...data];
    let formKeys = {};
    columns.forEach((col) => {
      if (col.editable)
        formKeys[
          typeof col.dataIndex === "object"
            ? col.dataIndex.join(".")
            : col.dataIndex
        ] = null;
    });
    formKeys = deepen(formKeys);

    newData.forEach((item) => {
      item.key = item.id ? item.id : item.id ? item.id : Math.random();
      item = { ...formKeys, ...item };
    });

    setDataState(newData);
    form.setFieldsValue([...newData]);
  }, [data]);

  const mergedColumns = [
    ...(addIndexColumn
      ? [
        {
          title: "No.",
          key: "index",
          width: 50,
          render: (text, record, index) => {
            if (pagination)
              return (
                (pagination.current - 1) * pagination.pageSize + index + 1
              );
            else return index + 1;
          },
          fixed: fixIndexColumn ? 'left' : undefined,
        },
      ]
      : []),
    ...columns.map((col) => {
      return {
        ...col,
        width: col.width ? col.width : 100,
        onCell: (record, index) => {
          let isCellEditable = true;
          // auth.userData.role === enums.Roles.ADMIN ||
          // auth.userData.role === enums.Roles.DEVELOPER;
          if (col.dataIndex && !isCellEditable) {
            const value =
              typeof col.dataIndex === "string"
                ? record[col.dataIndex]
                : _.get(record, col.dataIndex.join("."));
            isCellEditable = isEmpty(value);
          }

          return {
            record,
            getData: col.attribute
              ? (rawData) => {
                if (
                  rawData &&
                  typeof rawData === "object" &&
                  rawData.length > 0
                ) {
                  return rawData.map(
                    (el) =>
                      `• ${typeof el === "object"
                        ? accessObjectByString(el, col.attribute)
                        : el
                      }\n`
                  );
                } else return rawData;
              }
              : undefined,
            ...col,
            label: col.title,
            id: record.id,
            form,
            index,
            highlightedText,
            editing: col.editable && editable && isCellEditable,
            isLoading: loading,
          };
        },
      };
    }),
  ];

  const calculateTableWidth = () => {
    let width = 0;
    mergedColumns.forEach((col) => {
      width += col.width;
    });
    return width;
  };

  const tableHeaderClasses = classNames({
    [classes.tableHeaderNoData]: !dataState?.length,
    [classes.tableHeader]: true,
    [classes.noHeader]: !title,
    [classes.tableHeaderEditable]: editable || rowSelection,
  });

  const tableContainerClasses = classNames({
    [classes.tableContainer]: true,
    [classes.raisedTableContainer]: raised,
    [classes.tableContainerNoData]: !dataState?.length,
    [classes.editableTableContainer]: editable || rowSelection,
  });

  return (
    <div className={classes.root}>
      {filterProps || searchProps ? (
        <Filters
          filterProps={filterProps}
          searchProps={searchProps}
          handleSubmit={handleSubmit}
        />
      ) : null}
      <div className={tableContainerClasses}>
        <Form
          key={pagination ? pagination.current : 1}
          form={form}
          component={false}
        >
          <div className={classes.container}>
            <div className={tableHeaderClasses}>
              {title && <h4 className={classes.title}>{title}</h4>}
              {(editable && dataState?.length) || rowSelection ? (
                <Button
                  color="primary"
                  className={classes.submit}
                  onClick={() => editHandler(form)}
                  loading={isEditLoading}
                  disabled={loading}
                >
                  Submit
                </Button>
              ) : null}
            </div>

            <Table
              {...(rowSelection
                ? {
                  rowSelection: { type: rowSelectionType, ...rowSelection },
                }
                : {})}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              bordered
              dataSource={dataState}
              columns={mergedColumns}
              rowClassName={(_, index) => "editable-row " + (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
              pagination={
                pagination
                  ? {
                    ...pagination,
                    style: { marginRight: 20 },
                  }
                  : false
              }
              onChange={onChange}
              loading={loading}
              scroll={{ x: calculateTableWidth() }}
              size={size}
            />
          </div>
        </Form>
        {(editable && dataState?.length) || rowSelection ? (
          <div className={classes.footer}>
            <Button
              color="primary"
              className={classes.submit}
              onClick={() => editHandler(form)}
              loading={isEditLoading}
              disabled={loading}
            >
              Submit
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
}

CustomTable.defaultProps = {
  raised: true,
};
