import { createContext } from "react";

export const OrderContext = createContext({
  order: [],
  isGetOrderLoading: false,
  path: "",
  setOrder: () => {},
  getLatestOrder: () => {},
  updateQuotationGroups: () => {},
});
