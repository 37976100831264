import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { useYupValidationResolver } from "shared/hooks/yup-resolver-callback";
import ProfileValidation from "./Profile.validation";
import { toastError } from "utils/index";

//-------------------------------------------------------------------------------------

export const useProfile = () => {
  const auth = React.useContext(AuthContext);

  const [sendRequest, isLoading, sendRequestError] = useHttpClient();

  const dataResource = React.useMemo(() => api.users, []);

  React.useEffect(() => {
    if (sendRequestError?.message) {
      toastError(sendRequestError.message);
    }
  }, [sendRequestError]);

  React.useEffect(() => {
    if (auth.userData) {
      Object.keys(auth.userData).forEach((key) => {
        if (key !== "deletedAt" && key !== "updatedAt" && key !== "createdAt")
          setValue(key, auth.userData[key]);
      });
    }
  }, [auth.userData]);

  const resolver = useYupValidationResolver(ProfileValidation());

  const form = useForm({
    resolver,
    shouldUnregister: false,
    shouldFocusError: true,
  });

  const { handleSubmit, setValue, getValues } = form;

  const editHandler = (requestData) => async () => {
    try {
      const response = await sendRequest(
        dataResource.patch({
          ...requestData,
        })
      );
      delete requestData.password;
      auth.updateUserData(requestData);
      toast.success("Profile edited successfully");
    } catch (err) {
      toast.error("An error has occurred");
    }
  };

  const onError = () => {
    toast.error("Please fix the errors above and submit again.");
  };

  const onSubmit = (e) => {
    e.preventDefault();

    let requestData = { ...getValues() };

    handleSubmit(editHandler(requestData), onError)();
  };
  return {
    isLoading,
    onSubmit,
    form,
  };
};
