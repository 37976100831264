import { makeStyles } from "@material-ui/core/styles";
import CustomModal from "components/CustomModal/CustomModal";
import enums from "enums";
import PropTypes from "prop-types";
import React from "react";
import styles from "./AddNewOptionModal.styles";

//------------------------------------------------------------------------------
const useStyles = makeStyles(styles);

export default function AddNewOptionModal(props) {
  const { formComponent, isOpen, onClose } = props;

  const classes = useStyles();

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <div className={classes.formContainer}>
        {React.cloneElement(formComponent, { handleModalClose: onClose })}
      </div>
    </CustomModal>
  );
}

AddNewOptionModal.defaultProps = {
  getName: (data) => {
    const name = data ? data.name : "";
    return name;
  },
  type: enums.FormContainerType.PAGE,
  isOpen: false,
};

AddNewOptionModal.propTypes = {
  formComponent: PropTypes.element,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
