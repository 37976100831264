export const firebaseConfig = {
  local: {
    apiKey: "AIzaSyDt9--1ppIL4iJENkZ0n7IpH1g6gaxO5wE",
    authDomain: "ramtrade-dev.firebaseapp.com",
    projectId: "ramtrade-dev",
    storageBucket: "ramtrade-dev.appspot.com",
    messagingSenderId: "1036223817189",
    appId: "1:1036223817189:web:5814877367e1f79ff93b0c",
    measurementId: "G-SX54R6EDYZ",
  },
  staging: {
    apiKey: "AIzaSyAvl1tsKX8-fzuO_QzPEZv-Crc2DCVWBIc",
    authDomain: "ramtrade-staging.firebaseapp.com",
    projectId: "ramtrade-staging",
    storageBucket: "ramtrade-staging.appspot.com",
    messagingSenderId: "877521406852",
    appId: "1:877521406852:web:b7edd618dc7e9fc915abd1",
    measurementId: "G-J30NJVKE67",
  },
  production: {
    apiKey: "AIzaSyAXP5hYHkQrL0B_5PrXGS8qnwlByz1vGUY",
    authDomain: "ramtrade-prod.firebaseapp.com",
    projectId: "ramtrade-prod",
    storageBucket: "ramtrade-prod.appspot.com",
    messagingSenderId: "907806868540",
    appId: "1:907806868540:web:18679cf3344d8cc59f6f39",
    measurementId: "G-FLHVM7NDHY",
  },
};
