import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import PropTypes from "prop-types";
import React from "react";
import api from "services/api";
import styles from "./AddStockModal.styles";
import useAddStockModal from "./AddStockModal.hook";
import itemsForm from "views/ItemsManagement/itemsForm";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function AddStockModal(props) {
  const { isOpen, onClose, successCallback } = props;

  const classes = useStyles();

  const {
    isLoading,
    submitHandler,
    form,
    onError,
    handleSubmit,
  } = useAddStockModal({
    onClose,
    successCallback,
  });

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <AddIcon className={classes.icon} /> Add Stock
                </React.Fragment>
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(submitHandler, onError)();
                }}
                autoComplete="off"
              >
                <FormField
                  md={12}
                  name="item"
                  type="autocomplete"
                  label="Item"
                  options={{
                    getData: api.items.getAll,
                    getOptionLabel: (option) => option?.sapCode,
                    getSubmitValue: (option) => option?.id,
                    blurOnSelect: false,
                    loadOnFocus: true,
                  }}
                  form={form}
                />
                <FormField
                  md={12}
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                />
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    loading={isLoading}
                    style={{ margin: "20px 0" }}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

AddStockModal.defaultProps = {
  onClose: () => {},
};

AddStockModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  successCallback: PropTypes.func,
};
