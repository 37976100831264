import GridItem from "components/Grid/GridItem";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import React from "react";

//-------------------------------------------------------------------------------------

export default function InfoWithLabelGridItem(props) {
  const { xs, sm, md, ...rest } = props;
  return (
    <React.Fragment>
      <GridItem xs={xs ? xs : 12} sm={sm ? sm : 3} md={md ? md : 3}>
        <InfoWithLabel {...rest} />
      </GridItem>
      <GridItem xs={xs ? 1 : 12} sm={1} md={1} />
    </React.Fragment>
  );
}
