import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    sapCode: yup.mixed().required("Required"),
    serialRequired: yup.mixed().required("Required"),
    type: yup.mixed().required("Required"),
    description: yup.mixed().required("Required"),
    unitPrice: yup.mixed().required("Required"),
    unitCost: yup.mixed().required("Required"),
    warrantyExpirationPeriod: yup.mixed().required("Required"),
    manufacturer: yup.mixed().required("Required"),
  });
};
