import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import React from "react";
import api from "services/api";
import GroupIcon from "@material-ui/icons/Group";
import usersForm from "views/AccessManagement/usersForm";
import enums from "enums/index";
import translations from "constants/translations/index";

// appear in breadcrumbs and page headers
const listPageName = "Users";
const createPageName = "Create User";
const editPageName = "Edit User";

// appears on alerts; example: Entity created successfully
const entityName = "User";

const resource = enums.Resource.ACCESS_MANAGEMENT;

const layout = "/admin";

const path = "users";

const formStructure = usersForm;

const dataResource = api.users;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    canBeHighlighted: true,
  },
  {
    title: "Email",
    dataIndex: "email",
    canBeHighlighted: true,
  },
  {
    title: "Role",
    dataIndex: "roleCode",
    getData: (data) => translations["en"].roleCode[data],
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: GroupIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  superAdmin: true,
  layout: layout,
  // randomKey: true,
  children: true,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      resource,
      superAdmin: true,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      resource,
      superAdmin: true,
    },
  ],
};
