import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    sheetUrl: yup
      .mixed()
      .required("Orders Sheet is required"),
  });
};
