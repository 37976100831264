import { makeStyles } from "@material-ui/core/styles";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button";
import ConfirmationButton from "components/CustomButtons/ConfirmationButton/ConfirmationButton";
import CustomTable from "components/CustomTable/CustomTable";
import FilePreviewModal from "components/FilePreviewModal/FilePreviewModal";
import GridContainer from "components/Grid/GridContainer";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import Tabs from "components/Tabs/Tabs";
import enums from "enums/index";
import React, { useState } from "react";
import { formatEnums, getDateString } from "utils/index";
import { useWarrantyBatchView } from "./WarrantyBatchView.hook";
import styles from "./WarrantyBatchView.styles";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function WarrantBatchView(props) {
  const {
    batch,
    currentTab,
    isLoading,
    isBatchLoading,
    isDownloading,
    listData,
    currentPage,
    itemsPerPage,
    searchQuery,
    highlightedText,
    onSearchChange,
    handleSubmit,
    handleChange,
    handleDelete,
    handleDeleteSuccess,
    handleTabChange,
    downloadBatchFile,
  } = useWarrantyBatchView(props);

  const classes = useStyles();

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const columns = React.useMemo(
    () => [
      { title: "Private Key", dataIndex: ["privateKey"] },
      { title: "Public Key", dataIndex: ["publicKey"] },
      {
        title: "Status",
        dataIndex: ["status"],
        getData: (status) => formatEnums(status),
      },
      {
        title: "Activation Date",
        dataIndex: ["activationDate"],
        getData: (date) => (date && getDateString(date)) || "-",
      },
      {
        title: "Expiration Date",
        dataIndex: ["expirationPeriod"],
        getData: (date) => (date && getDateString(date)) || "-",
      },
    ],
    [batch]
  );

  const tabs = [
    {
      name: "Details",
      component: (
        <GridContainer>
          <InfoWithLabelGridItem label="Batch ID" info={batch?.id} />
          <InfoWithLabelGridItem
            label="Status"
            info={formatEnums(batch?.status)}
          />
          <InfoWithLabelGridItem
            label="Created by"
            info={batch?.createdBy?.name}
          />
          <InfoWithLabelGridItem
            label="Created at"
            info={getDateString(batch?.createdAt)}
          />
          <div className={classes.buttonsContainer}>
            <Button
              className={classes.viewButton}
              onClick={() => setIsPreviewOpen(true)}
              color="primary"
              view={true}
            />

            <ConfirmationButton
              entityName="Batch"
              name={`batch number ${batch?.id}`}
              onConfirm={handleDelete}
              onSuccess={handleDeleteSuccess}
              buttonProps={{ className: classes.deleteButton }}
            />

            <FilePreviewModal
              fileUrl={batch?.fileUrl}
              isOpen={isPreviewOpen}
              onClose={() => setIsPreviewOpen(false)}
              onDownload={downloadBatchFile}
              viewOnly={true}
              isLoading={isDownloading}
            />
          </div>
        </GridContainer>
      ),
      resource: enums.Resource.WARRANTIES,
    },
    {
      name: "Warranties",
      component: (
        <div style={{ marginTop: 20 }}>
          <CustomTable
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isBatchLoading || isLoading}
            highlightedText={highlightedText}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            columns={columns}
            addIndexColumn
          />
        </div>
      ),
      resource: enums.Resource.WARRANTIES,
    },
  ];

  return (
    <Card className={classes.cardRoot}>
      <CardBody>
        <h3 className={classes.header}>Batch {batch?.id}</h3>
        <Tabs
          buttonsContainerClasses={classes.tabsContainer}
          tabs={tabs}
          value={currentTab}
          onChange={handleTabChange}
        />
      </CardBody>
    </Card>
  );
}
