import React from "react";
import { AuthContext } from "shared/context/auth-context";

export default function CheckPermission({ resource, action, children }) {
  const auth = React.useContext(AuthContext);

  const isAccessible = React.useMemo(
    () => (resource ? auth.getAccessPermission(resource, action) : true),
    [auth.getAccessPermission, resource, action]
  );

  return true ? <React.Fragment>{children}</React.Fragment> : null; //Change isAccessible? after access levels are set up
}
