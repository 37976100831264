import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal";
import FormField from "components/Forms/FormField/FormField";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import React from "react";
import PropTypes from "prop-types";
import api from "services/api";
import styles from "../OrderItemModal/OrderItemModal.styles";
import useOrderItemModal from "./OrderItemModal.hook";
import enums from "enums/index";
import { displayItemName } from "utils/index";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import Skeleton from "@material-ui/lab/Skeleton";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderItemModal(props) {
  const { isOpen, onClose, item: itemProp, onAdd, onEdit, order } = props;

  const classes = useStyles();

  const {
    submitHandler,
    checkAndApplyPromoCode,
    removePromoCode,
    form,
    carBatterySize,
    isCheckPromoCodeLoading,
    promoCodeDisabled,
    remainingAmount,
    isLoading,
    productId,
    isCheckStockAvailabilityLoading,
  } = useOrderItemModal({
    itemProp,
    onClose,
    onAdd,
    onEdit,
  });
  const batterySize = carBatterySize
    ? carBatterySize
    : order?.car?.batterySize.size;

  return (
    <CustomModal
      open={isOpen}
      onClose={onClose}
      modalHeaderClassName={classes.modalHeader}
      modalBodyClassName={classes.modalBody}
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card style={{ margin: 0 }}>
            <CardBody>
              <div className={classes.formHeader}>
                {itemProp ? (
                  <React.Fragment>
                    <EditIcon className={classes.icon} /> Edit Order Item
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AddIcon className={classes.icon} />
                    Add Order Item
                  </React.Fragment>
                )}
              </div>
              <form
                style={{ padding: 20, paddingBottom: 0 }}
                onSubmit={submitHandler}
                autoComplete="off"
              >
                <InfoWithLabel
                  label="Recommended Battery Size"
                  info={batterySize ? batterySize : "N/A"}
                />
                <FormField
                  md={12}
                  name="product"
                  type="autocomplete"
                  label="Product"
                  form={form}
                  options={{
                    getData: () =>
                      api.products.getAll(undefined, undefined, undefined, {
                        type: enums.ProductType.NEW,
                      }),
                    getOptionLabel: (option) => displayItemName(option),
                    loadOnFocus: true,
                  }}
                />
                <FormField
                  md={12}
                  name="amount"
                  type="number"
                  label="Amount"
                  form={form}
                />
                <FormField
                  md={12}
                  name="promoCode.code"
                  type="text"
                  label="Promo Code"
                  form={form}
                  options={{
                    disabled: promoCodeDisabled,
                  }}
                />
                {promoCodeDisabled && (
                  <Button
                    color="danger"
                    className={classes.addButton}
                    onClick={removePromoCode}
                  >
                    Remove Promo Code
                  </Button>
                )}
                {!promoCodeDisabled && (
                  <Button
                    color="primary"
                    className={classes.addButton}
                    onClick={checkAndApplyPromoCode}
                    loading={isCheckPromoCodeLoading}
                  >
                    Apply Promo Code
                  </Button>
                )}
                {isCheckStockAvailabilityLoading ? (
                  <div className={classes.loadingContainer}>
                    <Skeleton variant="rect" height={30} width={300} />
                  </div>
                ) : (
                  <InfoWithLabel
                    md={5}
                    sm={5}
                    label="Available Stock for Selected Product"
                    info={productId ? remainingAmount : "N/A"}
                  />
                )}

                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    color="primary"
                    type="submit"
                    style={{ margin: "20px 0" }}
                    edit={!!itemProp}
                    create={!itemProp}
                    loadFromRight
                    loading={isLoading}
                  />
                </div>
              </form>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </CustomModal>
  );
}

OrderItemModal.defaultProps = {
  onClose: () => {},
};

OrderItemModal.propTypes = {
  item: PropTypes.object,
  onClose: PropTypes.func,
};
