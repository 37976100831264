import { dangerColor } from "assets/jss/material-dashboard-pro-react";

const styles = (theme) => ({
  // cardRoot: { height: 300 },
  header: {
    fontSize: 22,
    fontWeight: 400,
    margin: 0,
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    width: 530,
    justifyContent: "space-between",
  },
  viewButton: { width: 120, height: 35, marginLeft: 10, marginTop: 35 },
  deleteButton: { width: 120, height: 35, marginTop: 30 },
  tabsContainer: {
    borderBottom: "1px solid #ddd",
    margin: "30px -20px",
    marginBottom: 0,
    width: "calc(100% + 40px)",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: 0,
      marginTop: 20,
      padding: 0,
    },
  },
  warrantiesCollapseContainer: { margin: "35px 0" },
  tooltip: { transform: "translateY(-20px) !important" },
});

export default styles;
