import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import style from "./filesListStyle";
import Pagination from "components/Pagination/Pagination";
import FileCard from "./FileCard/FileCard";

const useStyles = makeStyles(style);

export default function FilesList(props) {
  const { files, removeHandler, names } = props;

  const [currentPage, setCurrentPage] = React.useState(1);

  const classes = useStyles();

  let postsPerPage = 10;

  let matchingFiles = files;
  let currentPageContent = files;

  const indexOfLastFile = currentPage * postsPerPage;
  const indexOfFirstFile = indexOfLastFile - postsPerPage;
  currentPageContent = matchingFiles.slice(indexOfFirstFile, indexOfLastFile);
  let currentPageContentElements = [];
  if (currentPageContent.length > 0) {
    currentPageContent.forEach((file, index) => {
      console.log("file", file);
      const fileName =
        typeof file === "object"
          ? file.name
          : decodeURIComponent(file).split("/").reverse()[0].split("?")[0];
      console.log("fileName", fileName);

      currentPageContentElements.push(
        <FileCard
          key={Math.random()}
          file={{
            name: names?.[index]
              ? `${names[index]}.${fileName?.split(".")?.reverse()[0]}`
              : fileName,

            skipNameProcessing: !!names?.[index] || typeof file === "object",
            url: file,
          }}
          removeHandler={() => {
            if (files.length - 1 <= postsPerPage * (currentPage - 1)) {
              setCurrentPage(currentPage - 1);
            }
            removeHandler(index);
          }}
        />
      );
      console.log("file", file);
    });
  }
  return (
    <div className={classes.root}>
      {files?.length > 0 ? (
        <React.Fragment>
          <div className={classes.filesContainer}>
            {currentPageContentElements}
          </div>
          {postsPerPage >= files?.length ? null : (
            <div className={classes.paginationContainer}>
              <Pagination
                color="primary"
                postsPerPage={postsPerPage}
                totalPosts={files?.length}
                paginate={(page) => {
                  setCurrentPage(page);
                }}
                currentPage={currentPage}
              />
            </div>
          )}
        </React.Fragment>
      ) : null}
    </div>
  );
}
