import { makeStyles } from "@material-ui/core/styles";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.js";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";
import GridContainer from "components/Grid/GridContainer.js";
import Loading from "components/Loading/Loading";
import api from "services/api";
import { useOrderDetailsForm } from "./OrderDetailsForm.hook";
import styles from "./OrderDetailsForm.styles";
import React from "react";
import { useTranslation } from "react-i18next";
import { OrderDetailsFormContext } from "./OrderDetailsForm.context";
import OrderItems from "./components/OrderItems/OrderItems";
import InfoWithLabelGridItem from "components/InfoWithLabel/InfoWithLabelGridItem";
import InfoWithLabel from "components/InfoWithLabel/InfoWithLabel";
import translations from "constants/translations/index";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderDetailsForm(props) {
  const { create, edit, setIsEditing, path } = props;
  const classes = useStyles();
  const {
    isLoading,
    onSubmit,
    form,
    isGetOrderLoading,
    customer,
    defaultOrderItem,
    plannedDeliveryCityId,
    car,
    totalPrice,
    handleAddNewCar,
  } = useOrderDetailsForm({
    edit,
    setIsEditing,
    path,
  });
  const { i18n } = useTranslation();

  return (
    <OrderDetailsFormContext.Provider
      value={{
        form,
        defaultOrderItem,
        isNewOrder: !!create,
        carBatterySize: car?.batterySize?.size,
      }}
    >
      <form className={classes.form} onSubmit={onSubmit} autoComplete="off">
        <Loading loading={isGetOrderLoading} style={{ height: "60vh" }}>
          <div className={classes.section}>
            <div className={classes.headerContainer}>
              <h3>Lead Info</h3>
              {edit && (
                <Button
                  simple
                  className={classes.closeButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setIsEditing(false)}
                  color="danger"
                >
                  <Close className={classes.closeIcon} />
                </Button>
              )}
            </div>
            <GridContainer>
              <InfoWithLabelGridItem
                md={5}
                sm={5}
                label="Battery Size"
                info={car?.batterySize ? car?.batterySize?.size : "N/A"}
              />
              <FormFieldGridItem
                type="select"
                form={form}
                name="channel"
                label="Channel"
                options={{
                  data: Object.keys(
                    translations[i18n.language].customerChannel
                  ).map((key) => ({
                    value: key,
                    name: translations[i18n.language].customerChannel[key],
                  })),
                }}
              />
              <FormFieldGridItem
                type="select"
                form={form}
                name="leadFollowUp"
                label="Lead Follow Up"
                options={{
                  enum: "leadFollowUp",
                }}
              />
              <FormFieldGridItem
                type="datetime-local"
                form={form}
                name="followUpDate"
                label="Follow Up Date"
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="leadLossReason"
                label="Lead Loss Reason"
                options={{
                  getData: api.orderLossReasons.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                }}
              />
              <FormFieldGridItem
                name="notes"
                type="text"
                label="Notes"
                form={form}
                options={{
                  rows: 4,
                  multiline: true,
                }}
              />
            </GridContainer>
          </div>
          <div className={classes.section}>
            <h3>Order Info</h3>
            <GridContainer>
              <FormFieldGridItem
                type="select"
                form={form}
                name="deliveryType"
                label="Delivery Type"
                options={{
                  enum: "deliveryType",
                }}
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="paymentMethod"
                label="Payment Method"
                options={{
                  getData: api.paymentMethods.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                }}
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="zone"
                label="Zone Delivery Fees"
                options={{
                  getData: api.zones.getAll,
                  getOptionLabel: (option) => option.name + " - " + option.fees,
                  getSubmitValue: (option) => option?.id,
                  blurOnSelect: false,
                  loadOnFocus: true,
                }}
              />
              <FormFieldGridItem
                type="datetime-local"
                form={form}
                name="plannedDeliveryTime"
                label="Planned Delivery Date/Time"
              />
              <FormFieldGridItem
                type="datetime-local"
                form={form}
                name="desiredDeliveryTime"
                label="Desired Delivery Date/Time"
              />
              <FormFieldGridItem
                type="text"
                form={form}
                name="plannedDeliveryStreet"
                label="Planned Delivery Street"
              />
              <InfoWithLabelGridItem
                md={5}
                sm={5}
                label="Default City "
                info={customer?.defaultDistrict?.city?.name ?? "N/A"}
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="plannedDeliveryCity"
                label="Planned Delivery City"
                options={{
                  getData: api.cities.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  loadOnFocus: true,
                  blurOnSelect: false,
                }}
              />
              <InfoWithLabelGridItem
                md={5}
                sm={5}
                label="Default District "
                info={customer?.defaultDistrict?.name ?? "N/A"}
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="plannedDeliveryDistrict"
                label="Planned Delivery District"
                options={{
                  getData: api.districts.getAll,
                  getOptionLabel: (option) => option.name,
                  getSubmitValue: (option) => option?.id,
                  filters: { cityId: plannedDeliveryCityId },
                  loadOnFocus: true,
                  blurOnSelect: false,
                  disabled: !plannedDeliveryCityId,
                  disabledTooltipText: "Please select a city first",
                }}
              />
              <FormFieldGridItem
                type="text"
                form={form}
                name="plannedDeliveryCoordinates"
                label="Planned Delivery Coordinates (lat,lng) "
              />
            </GridContainer>
          </div>
          <OrderItems form={form} />
          <div className={classes.divTotalPrice}>
            <InfoWithLabel label="Total Price" info={totalPrice ?? "N/A"} />
          </div>

          <div className={classes.section}>
            <h3>Matching Info</h3>
            <GridContainer>
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="assignedDriver"
                label="Assigned Driver"
                options={{
                  getData: api.drivers.getAll,
                  getOptionLabel: (option) => option.name,
                  loadOnFocus: true,
                }}
              />
              <InfoWithLabelGridItem
                md={5}
                sm={5}
                label="Default truck for selected driver"
                info={
                  form.watch("assignedDriver")?.defaultTruck?.truckNumber ||
                  "N/A"
                }
              />
              <FormFieldGridItem
                type="autocomplete"
                form={form}
                name="assignedTruck"
                label="Assigned Truck"
                options={{
                  getData: api.trucks.getAll,
                  getOptionLabel: (option) => option.truckNumber,
                  loadOnFocus: true,
                }}
              />
            </GridContainer>
          </div>
          <div className={classes.buttonsContainer}>
            {edit && (
              <Button
                color="danger"
                onClick={() => setIsEditing(false)}
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              loading={isLoading}
              style={{ marginTop: 20 }}
              create={create}
              edit={edit}
              loadFromRight
            />
          </div>
        </Loading>
      </form>
    </OrderDetailsFormContext.Provider>
  );
}
