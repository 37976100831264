import { makeStyles } from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import { dangerColor } from "assets/jss/material-dashboard-pro-react";
import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";
import { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify/dist/index";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";
import { FormHelperText } from "../../../node_modules/@material-ui/core/index";
import dropFilesStyle from "./dropFilesStyle";
import FileCard from "./FilesList/FileCard/FileCard";
import FilesList from "./FilesList/FilesList";
import React from "react";
const useStyles = makeStyles(dropFilesStyle);

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 15,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "white",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  marginBottom: 15,
  cursor: "pointer",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: dangerColor[0],
};

export default function DropFiles(props) {
  let {
    label,
    files,
    maxFiles,
    errorMessage,
    shouldUploadTemp,
    setFiles,
    onRemoveAttachment,
    onAddAttachments,
    names,
  } = props;
  const classes = useStyles();
  const [sendRequest, isLoading] = useHttpClient();

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      let newFiles = [];
      if (maxFiles) {
        newFiles = files
          ? [...[...acceptedFiles, ...files].slice(0, maxFiles)]
          : [...acceptedFiles.slice(0, maxFiles)];
      } else {
        newFiles = files ? [...files, ...acceptedFiles] : [...acceptedFiles];
      }

      if (shouldUploadTemp) {
        if (maxFiles === 1 && newFiles.length === 1) {
          try {
            const response = await sendRequest(api.uploadFile(newFiles[0]));
            if (response) {
              setFiles(encodeURIComponent(response));
            }
          } catch (error) {
            toast.error("An error has occurred. Please try again later.");
          }
        } else if (maxFiles > 1 && newFiles.length > 0) {
          try {
            const response = await sendRequest(api.uploadFiles(newFiles));
            if (response) {
              const encodedResponse = response.map((url) =>
                encodeURIComponent(url)
              );
              if (onAddAttachments) {
                onAddAttachments(response);
              }
              setFiles(encodedResponse);
            }
          } catch (error) {
            toast.error("An error has occurred. Please try again later.");
          }
        }
      } else {
        newFiles = newFiles.length > 0 ? newFiles : null;
        setFiles(newFiles);
      }
    }
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop }); // accept: "image/*"

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject || !!errorMessage ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, errorMessage]
  );

  let filesData = [];

  if (typeof files === "array" && files.length > 0) {
    files.forEach((file, index) => {
      filesData.push([
        <span key={index}>{file.name}</span>,
        // <span key={index}>{file.name.split(".").pop().toUpperCase()}</span>,
        <DeleteIcon
          onClick={() => onFileRemoved(index)}
          className={classes.deleteIcon}
        />,
      ]);
    });
  }

  const onFileRemoved = (index) => {
    let updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    updatedFiles = updatedFiles.length > 0 ? updatedFiles : null;
    setFiles(updatedFiles);
    if (onRemoveAttachment) onRemoveAttachment(index);
  };

  return (
    <div className={classes.root}>
      {label ? (
        <p className={errorMessage ? classes.labelRootError : ""}>{label}</p>
      ) : null}
      <div className={classes.container}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          <CloudUploadIcon className={classes.uploadIcon} />
          <p style={{ marginBottom: 0 }}>
            Drag & Drop files here, or click to select files
          </p>
        </div>
        {isLoading ? (
          <CustomLinearProgress color="secondary" />
        ) : typeof files === "object" && files?.length > 0 ? (
          <FilesList
            files={files}
            names={names}
            removeHandler={onFileRemoved}
          />
        ) : typeof files === "string" ? (
          <FileCard
            file={{
              name: decodeURIComponent(files)
                .split("/")
                .reverse()[0]
                .split("?")[0],
              url: files,
            }}
            removeHandler={() => {
              setFiles(null);
            }}
          />
        ) : null}
        <FormHelperText
          id="component-error-text"
          className={classes.labelRootError}
        >
          {errorMessage ? `* ${errorMessage}` : null}
        </FormHelperText>
      </div>
    </div>
  );
}
