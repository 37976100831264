import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import CheckPermission from "components/CheckPermission/CheckPermission";
import Button from "components/CustomButtons/Button.js";
import ConfirmModal from "components/ConfirmModal/ConfirmModal.js";
import CustomTable from "components/CustomTable/CustomTable";
import enums from "enums/index";
import React from "react";
import classNames from "classnames";
import OrderItemModal from "./components/OrderItemModal/OrderItemModal";
import useOrderItems from "./OrderItems.hook";
import styles from "./OrderItems.styles";
import { isOrderEditable } from "utils/index";
import { AuthContext } from "shared/context/auth-context";
import CustomModal from "components/CustomModal/CustomModal";
import QRCode from "react-qr-code";
import CircularProgress from "@material-ui/core/CircularProgress";

//-------------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function OrderItems() {
  const classes = useStyles();

  const {
    order,
    isOpen,
    isConfirmModalOpen,
    currentAction,
    allItemsLinked,
    isAssignModalOpen,
    isGetItemsLoading,
    editedItem,
    orderItems,
    isQRCodeLoading,
    warrantyLinkingUrl,
    onClose,
    setIsConfirmModalOpen,
    onAssignModalClose,
    handleAssignItem,
    generateOrderWarrantiesLink,
    handleConfirmProcessCancellation,
    handleScanningProcessEnd,
  } = useOrderItems();

  const { userData } = React.useContext(AuthContext);

  var modalInnerContainerClasses = classNames({
    [classes.modal]:
      currentAction !== enums.OrderWarrantyLinkAction.COMPLETE &&
      currentAction !== enums.OrderWarrantyLinkAction.CANCEL,
  });

  const columns = React.useMemo(
    () => [
      {
        title: "SAP Code",
        dataIndex: ["item", "sapCode"],
      },
      {
        title: "Amount",
        dataIndex: "amount",
      },
      {
        title: "Unit Price",
        dataIndex: "unitPrice",
      },
      {
        title: "Total Price",
        dataIndex: "totalPrice",
      },
    ],
    [order]
  );

  const modalContent = (title, description) => (
    <div>
      <h4 className={classes.modalHeader + " " + classes.success}>{title}</h4>
      <div className={classes.modalBody}>
        <p>{description}</p>
      </div>
      <div className={classes.modalFooter}>
        <Button
          color="transparent"
          className={classes.button + " " + classes.cancel}
          onClick={handleScanningProcessEnd}
          round
        >
          OK
        </Button>
      </div>
    </div>
  );

  return (
    <div className={classes.root}>
      <div className={classes.section}>
        {isAssignModalOpen && (
          <OrderItemModal
            isOpen={isAssignModalOpen}
            onClose={onAssignModalClose}
            create={!editedItem}
            edit={!!editedItem}
            item={editedItem}
          />
        )}

        <ConfirmModal
          isOpen={isConfirmModalOpen}
          onClose={() => {
            setIsConfirmModalOpen(false);
          }}
          headerMessage="Are you sure?"
          confirmMessage="Are you sure you want to cancel this process?"
          confirmButtonText="Confirm"
          confirmButtonColor="success"
          cancelButtonText="Cancel"
          successHeaderText="Process Cancelled"
          errorMessage="An error has occurred"
          successMessage="Warranties linking has been cancelled"
          onConfirm={handleConfirmProcessCancellation}
        />

        <React.Fragment>
          <div className={classes.headerContainer}>
            <h3 className={classes.header}>Order Items</h3>
            <div className={classes.buttonsContainer}>
              {isOrderEditable(order, userData) && (
                <CheckPermission action={enums.Action.CREATE}>
                  <Button
                    onClick={() => handleAssignItem()}
                    className={classes.addButton}
                    color="success"
                  >
                    <AddIcon className={classes.addIcon} />
                    Add Order Item
                  </Button>
                </CheckPermission>
              )}
              {!allItemsLinked ? (
                <Button
                  onClick={generateOrderWarrantiesLink}
                  color="primary"
                  loading={isQRCodeLoading}
                >
                  Link Warranties
                </Button>
              ) : null}
              {warrantyLinkingUrl ? (
                <CustomModal
                  open={isOpen}
                  onClose={onClose}
                  modalInnerContainerClassName={modalInnerContainerClasses}
                  modalRootClass={classes.modalRoot}
                >
                  {currentAction === enums.OrderWarrantyLinkAction.ACTIVATE ? (
                    <>
                      <p className={classes.modalTitle}>
                        Scanning warranties...
                      </p>
                      <CircularProgress className="circular-progress" />
                    </>
                  ) : currentAction ===
                    enums.OrderWarrantyLinkAction.COMPLETE ? (
                    modalContent(
                      "Scanning completed",
                      "Scanning warranties has been completed successfully"
                    )
                  ) : currentAction === enums.OrderWarrantyLinkAction.CANCEL ? (
                    modalContent(
                      "Scanning cancelled",
                      "Scanning warranties has been cancelled"
                    )
                  ) : (
                    <>
                      <p className={classes.modalTitle}>
                        Please scan this QR code to link items with warranties
                      </p>
                      <QRCode
                        className={classes.qrCodeContainer}
                        value={warrantyLinkingUrl}
                      />
                    </>
                  )}
                </CustomModal>
              ) : null}
            </div>
          </div>
          <div className={classes.tableContainer}>
            <CustomTable
              columns={columns}
              data={orderItems}
              loading={isGetItemsLoading}
              addIndexColumn
            />
          </div>
        </React.Fragment>
      </div>
    </div>
  );
}
