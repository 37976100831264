import translations from "constants/translations/index";
import enums from "enums/index";
import * as yup from "yup";

export default [
  {
    column: 1,
    fields: [
      {
        name: "resource",
        type: "select",
        label: "Resource",
        options: {
          data: Object.keys(translations["en"].resource).map((key) => ({
            value: key,
            name: translations["en"].resource[key],
          })),
        },
        validation: yup.mixed().required("Required"),
      },
      {
        name: "roleCode",
        type: "select",
        label: "Role",
        options: {
          data: Object.keys(translations["en"].roleCode).flatMap((key) =>
            key !== enums.RoleCode.SUPER_ADMIN
              ? {
                  value: key,
                  name: translations["en"].roleCode[key],
                }
              : []
          ),
        },
        validation: yup.mixed().required("Required"),
      },
    ],
  },
];
