import {
  blackColor,
  hexToRgb,
} from "assets/jss/material-dashboard-pro-react.js";

const pagesStyle = (theme) => ({
  wrapper: {
    height: "auto",
    minHeight: "100vh",
    position: "relative",
    top: "0",
  },
  container: {
    maxWidth: 1100,
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    marginTop: 40,
    [theme.breakpoints.down("sm")]: {
      marginTop: 10,
    },
  },
  fullPage: {
    padding: "120px 0",
    paddingTop: 40,
    position: "relative",
    minHeight: "100vh",
    display: "flex!important",
    margin: "0",
    border: "0",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "100%",
    "& footer": {
      position: "absolute",
      bottom: "0",
      width: "100%",
      border: "none !important",
    },
    "&:before": {
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.65)",
    },
  },
});

export default pagesStyle;
