import React, { useEffect, useState } from "react";
import { Transition } from "react-transition-group";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import CustomModal from "components/CustomModal/CustomModal.js";
import classNames from "classnames";
import { primaryColor } from "assets/jss/material-dashboard-pro-react";
import styles from "./filePreviewModalStyle";

const useStyles = makeStyles(styles);

export default function FilePreviewModal(props) {
  const {
    isOpen,
    onClose,
    file,
    viewOnly = false,
    hideControls = false,
    onDownload,
    isLoading,
    fileUrl,
    fileName,
  } = props;
  const classes = useStyles();
  const [objectUrl, setObjectUrl] = useState(null);

  useEffect(() => {
    if (!file) return;
    const objectUrl = URL.createObjectURL(file);
    setObjectUrl(file);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const modalClasses = classNames({
    [classes.modal]: true,
    [classes.hidePdfDownload]: viewOnly,
    [classes.hideControls]: hideControls,
  });

  return (
    <Transition mountOnEnter unmountOnExit in={isOpen} timeout={300}>
      <CustomModal
        open={isOpen}
        onClose={onClose}
        modalInnerContainerClassName={modalClasses}
      >
        <DocViewer
          documents={[
            { uri: objectUrl || fileUrl, fileName: file?.name || fileName },
          ]}
          pluginRenderers={DocViewerRenderers}
          prefetchMethod="GET"
          config={{
            header: {
              disableHeader: true,
              disableFileName: true,
              retainURLParams: true,
              disableThemeScrollbar: true,
            },
          }}
          theme={{
            primary: primaryColor[0],
            secondary: "#ffffff",
            textPrimary: "#3c4858",
            disableThemeScrollbar: true,
          }}
          style={onDownload && { height: "90%", marginBottom: 20 }}
        />
        {onDownload ? (
          <div className={classes.buttonContainer}>
            <Button
              onClick={onDownload}
              color="primary"
              loadFromRight
              loading={isLoading}
              className={classes.button}
            >
              Download
            </Button>
          </div>
        ) : null}
      </CustomModal>
    </Transition>
  );
}

FilePreviewModal.defaultProps = {
  isOpen: false,
};

FilePreviewModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  file: PropTypes.object,
  viewOnly: PropTypes.bool,
  onDownload: PropTypes.func,
  isLoading: PropTypes.bool,
  fileUrl: PropTypes.string,
  fileName: PropTypes.string,
};
