const filePreviewModalStyle = (theme) => ({
  buttonContainer: {
    display: "flex",
    width: "100%",
  },
  button: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      margin: "auto",
    },
  },
  modal: { width: 1000, height: 600 },
  hideControls: { "& #pdf-controls": { display: "none" } },
  hidePdfDownload: { "& #pdf-download": { display: "none" } },
});

export default filePreviewModalStyle;
