import {
  dangerColor,
  hexToRgb,
  infoColor,
} from "assets/jss/material-dashboard-pro-react";

const fileCardStyle = (theme) => ({
  root: {
    width: "100%",
    display: "flex",
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
    backgroundColor: "white",
    height: 60,
    margin: "10px 0px",
    padding: "6px",
    alignItems: "center",
  },
  icon: {
    marginRight: 10,
    maxHeight: 50,
    width: 30,
    objectFit: "contain",
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    width: "calc(100% - 75px)",
  },
  name: {
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
  },
  size: {
    fontSize: 13,
    fontWeight: 200,
    margin: 0,
  },
  buttonsContainer: {
    display: "flex",
    marginLeft: "auto",
  },
  downloadIcon: {
    color: "rgba(" + hexToRgb(infoColor[0]) + ", 0.26)",
    cursor: "pointer",
    transition: "all 0.3s",
    marginRight: 10,
    height: 28,
    width: 28,
    "&:hover": {
      color: infoColor[0],
    },
  },
  deleteIcon: {
    color: "rgba(" + hexToRgb(dangerColor[0]) + ", 0.26)",
    cursor: "pointer",
    transition: "all 0.3s",
    marginRight: 10,
    height: 28,
    width: 28,
    "&:hover": {
      color: dangerColor[0],
    },
  },
});

export default fileCardStyle;
