const collapseContainerStyle = (theme) => ({
  root: {
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: 0,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    borderBottom: "1px solid #eee",
    cursor: "pointer",
  },
  collapseContainer: {
    padding: "0px 20px",
  },
  expandIcon: {
    fontSize: 22,
    transform: "rotateZ(0deg)",
    transition: "all 0.3s ease-out",
    marginLeft: 10,
    border: "1px solid #ddd",
    borderRadius: 100,
  },
  iconMore: {
    transform: "rotateZ(0deg)",
  },
  iconLess: {
    transform: "rotateZ(-180deg)",
  },
});

export default collapseContainerStyle;
