import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import enums from "enums/index";
import React from "react";
import DataList from "components/DataList/DataList";
import api from "services/api";
import warrantyBatchesForm from "views/Warranties/warrantyBatchesForm";
import WarrantyBatchView from "views/Warranties/WarrantyBatchView/WarrantyBatchView";
import { formatEnums, getDateString } from "utils/index";

// appear in breadcrumbs and page headers
const listPageName = "Batches";

const resource = enums.Resource.WARRANTIES;

const layout = "/admin";

const path = "warranties";

const entityName = "Warranty Batch";

const formStructure = warrantyBatchesForm;

const dataResource = api.warrantyBatches;

const columns = [
  {
    title: "Batch ID",
    dataIndex: "id",
  },
  {
    title: "Status",
    dataIndex: "status",
    getData: (data) => formatEnums(data),
  },
  {
    title: "Created by",
    dataIndex: ["createdBy", "name"],
  },
  {
    title: "Created at",
    dataIndex: "createdAt",
    getData: (data) => getDateString(data),
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: CollectionsBookmarkIcon,
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ view: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  resource,
  layout,
  children: true,
  views: [
    {
      path: `/${path}/:id`,
      render: () => {
        return <WarrantyBatchView />;
      },
      layout: layout,
      action: "read",
      //   getName: async (id) => {
      //     try {
      //       const data = (await dataResource.getOne(id)).data;
      //       return data.name;
      //     } catch {
      //       return viewPageName;
      //     }
      //   },
      resource,
    },
  ],
};
