import * as yup from "yup";

//----------------------------------------------------------

export default () => {
  return yup.object().shape({
    item: yup.mixed().required("Required"),
    //amount should be greater than 0
    amount: yup
      .number()
      .required("Required")
      .moreThan(0, "Amount should be greater than 0"),
  });
};
