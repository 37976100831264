import { makeStyles } from "@material-ui/core/styles";
import BarChartIcon from "@material-ui/icons/BarChart";
import Button from "components/CustomButtons/Button.js";
import CustomTable from "components/CustomTable/CustomTable";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import translations from "constants/translations/index";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import useItems from "./Items.hook";
import styles from "./Items.styles";
import AddItemModal from "./components/AddItemModal/AddItemModal";
import AddStockModal from "./components/AddStockModal/AddStockModal";

//------------------------------------------------------------------------------

const useStyles = makeStyles(styles);

export default function Items(props) {
  const classes = useStyles();

  const { resource } = props;

  const filterInputs = useMemo(() => [], []);

  const {
    currentPage,
    listData,
    searchQuery,
    filtersState,
    itemsPerPage,
    highlightedText,
    isGetLoading,
    isAddItemModalOpen,
    isAddStockModalOpen,
    onSearchChange,
    onFilterChange,
    handleChange,
    handleSubmit,
    getData,
    setIsAddItemModalOpen,
    setIsAddStockModalOpen,
  } = useItems({
    resource,
    filterInputs,
  });

  const columns = React.useMemo(
    () => [
      {
        dataIndex: ["sapCode"],
        title: "SAP Code",
        canBeHighlighted: true,
        width: 120,
      },
      {
        dataIndex: ["manufacturer", "name"],
        title: "Manufacturer",
        width: 120,
      },
      {
        dataIndex: ["type"],
        title: "Type",
        width: 120,
        getData: (data) => {
          if (data) return translations["en"].itemType[data];
          else return "-";
        },
      },
      {
        dataIndex: ["group"],
        title: "Group",
        width: 120,
        getData: (data) => {
          if (data) return translations["en"].itemGroup[data];
          else return "-";
        },
      },
      {
        dataIndex: ["description"],
        title: "Description",
        width: 120,
      },
      {
        dataIndex: ["unitPrice"],
        title: "Unit Price",
        width: 100,
      },
      {
        dataIndex: ["warrantyExpirationPeriod"],
        title: "Exp. Period (Months)",
        width: 120,
      },
      {
        dataIndex: ["stockItemCount"],
        title: "Stock Count",
        width: 120,
      },
    ],
    [listData]
  );

  return (
    <div>
      {isAddItemModalOpen && (
        <AddItemModal
          isOpen={isAddItemModalOpen}
          onClose={() => {
            setIsAddItemModalOpen(false);
            getData();
          }}
          key={isAddItemModalOpen}
        />
      )}
      {isAddStockModalOpen && (
        <AddStockModal
          isOpen={isAddStockModalOpen}
          onClose={() => {
            setIsAddStockModalOpen(false);
            getData();
          }}
          key={isAddStockModalOpen}
        />
      )}
      <div className={classes.topContainer}>
        <div className={classes.buttonsContainer}>
          <Button
            create
            color="white"
            onClick={() => setIsAddItemModalOpen(true)}
          >
            Add Item
          </Button>
          <Button color="white" onClick={() => setIsAddStockModalOpen(true)}>
            <BarChartIcon className={classes.addIcon} />
            Add Stock
          </Button>
        </div>
      </div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <CustomTable
            columns={columns}
            data={listData.rows}
            onChange={handleChange}
            pagination={{
              total: listData.count,
              current: currentPage,
              pageSize: itemsPerPage,
              position: ["topRight"],
              pageSizeOptions: [10, 20, 50],
            }}
            loading={isGetLoading}
            highlightedText={highlightedText}
            filterProps={{
              value: filtersState,
              onChange: onFilterChange,
              // inputs: filterInputs,
            }}
            searchProps={{
              onChange: onSearchChange,
              value: searchQuery,
              placeholder: "Search",
            }}
            handleSubmit={handleSubmit}
            title="Items Summary"
            addIndexColumn
            size="middle"
            fixIndexColumn
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}

Items.propTypes = {
  resource: PropTypes.string,
};
