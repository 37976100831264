import CircularProgress from "@material-ui/core/CircularProgress";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import AppLayout from "layouts/App";

import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Router, Switch } from "react-router";
import { Redirect } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";
import { setLocaleCookie } from "utils";
import "./App.css";

var hist = createBrowserHistory();

const App = () => {
  const {
    userData,
    login,
    logout,
    getAccessPermission,
    updateUserData,
  } = useAuth();

  const { i18n } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setLocaleCookie(i18n.language);
  }, [i18n]);

  React.useEffect(() => {
    if (userData !== false) {
      setIsLoading(false);
    }
  }, [userData]);

  let routes;

  if (!!userData) {
    routes = [
      <Route path="/admin" component={AdminLayout} key="admin-route" />,
      <Route path="/app" component={AppLayout} key="app-route" />,
      <Redirect from="/" to="/admin/admins" key="redirect" />,
    ];
  } else {
    routes = [
      <Route path="/app" component={AppLayout} key="app-route" />,
      <Route path="/auth" component={AuthLayout} key="auth-route" />,
      <Redirect to="/auth" key="redirect" />,
    ];
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!userData,
        userData: userData,
        login: login,
        logout: logout,
        getAccessPermission: getAccessPermission,
        updateUserData: updateUserData,
      }}
    >
      <Router history={hist}>
        <main>
          <ScrollToTop />
          <ToastContainer transition={Slide} />
          {isLoading ? (
            <div className="loading-container">
              <CircularProgress className="circular-progress" />
            </div>
          ) : (
            <div className="wrapper">
              <Switch>{routes}</Switch>
            </div>
          )}
        </main>
      </Router>
    </AuthContext.Provider>
  );
};
export default App;
