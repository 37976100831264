import enums from "enums/index";
import itemsRoutes from "./itemsRoutes";
import StoreIcon from '@material-ui/icons/Store';
import manufacturersRoutes from "./manufacturersRoutes";

export default {
  name: "Items Management",
  icon: StoreIcon,
  layout: "/admin",
  collapse: true,
  state: "itemsManagementCollapse",
  superAdmin: true,
  resource: enums.Resource.ITEMS_MANAGEMENT,
  views: [itemsRoutes, manufacturersRoutes],
};
