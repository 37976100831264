import FormField from "components/Forms/FormField/FormField";
import GridItem from "components/Grid/GridItem.js";
import React from "react";

//------------------------------------------------------------------------------

export default function FormFieldGridItem(props) {
  const { xs, sm, md, ...rest } = props;
  return (
    <React.Fragment>
      <GridItem xs={xs ? xs : 12} sm={sm ? sm : 12} md={md ? md : 5}>
        <FormField {...rest} />
      </GridItem>
      <GridItem xs={xs ? 1 : 12} sm={sm ? 1 : 12} md={1} />
    </React.Fragment>
  );
}
