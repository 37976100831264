import React from "react";
import ScanningWarranties from "views/OrderWarrantiesLinking/pages/ScanningWarranties/ScanningWarranties/";

// appear in breadcrumbs and page headers
const pageName = "Warranties Linking";

const layout = "/app";

const path = "warranties-linking";

export default {
  path: `/${path}`,
  name: pageName,
  render: () => <ScanningWarranties />,
  layout: layout,
  randomKey: true,
  hide: true,
  //   children: true,
  //   views: [
  //     {
  //       path: `/${path}/success`,
  //       name: createPageName,
  //       render: () => <RegistrationSuccess />,
  //       layout: layout,
  //       randomKey: true,
  //     },
  //   ],
};
