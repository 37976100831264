import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PermIdentity from "@material-ui/icons/PermIdentity";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import styles from "./Profile.styles";
import { useProfile } from "./Profile.hook";
import FormFieldGridItem from "components/Forms/FormField/FormFieldGridItem";

const useStyles = makeStyles(styles);

export default function Profile() {
  const { isLoading, onSubmit, form } = useProfile();

  const classes = useStyles();

  return (
    <form onSubmit={onSubmit}>
      <GridContainer>
        <GridItem xs={12} sm={12} md={5}>
          <Card>
            <CardBody>
              <div className={classes.formHeader}>
                <React.Fragment>
                  <PermIdentity className={classes.icon} />
                  Edit Profile
                </React.Fragment>
              </div>
              <GridContainer>
                <FormFieldGridItem
                  md="12"
                  type="text"
                  form={form}
                  name="name"
                  label="Name"
                />
              </GridContainer>
              <GridContainer>
                <FormFieldGridItem
                  md="12"
                  type="text"
                  form={form}
                  name="email"
                  label="Email address"
                />
              </GridContainer>
              <GridContainer>
                <FormFieldGridItem
                  md="12"
                  type="text"
                  form={form}
                  name="phone"
                  label="Phone"
                />
              </GridContainer>
              <GridContainer>
                <FormFieldGridItem
                  md="12"
                  type="text"
                  form={form}
                  name="title"
                  label="Title"
                />
              </GridContainer>
              <GridContainer>
                <FormFieldGridItem
                  md="12"
                  type="text"
                  form={form}
                  name="password"
                  label="Password"
                />
              </GridContainer>
              <Button
                color="primary"
                type="submit"
                loading={isLoading}
                className={classes.updateProfileButton}
                style={{ marginTop: 20 }}
                loadFromRight
              >
                Edit
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </form>
  );
}
