import React from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import api from "services/api";
import { useHttpClient } from "shared/hooks/http-hook";

//-------------------------------------------------------------------------------------

export const useOrderView = ({ path }) => {
  const [sendRequest, isGetOrderLoading] = useHttpClient();

  const [order, setOrder] = React.useState(null);

  const [currentTab, setCurrentTab] = React.useState(0);

  const [isOpen, setIsOpen] = React.useState(false);

  const history = useHistory();

  const dataResource = React.useMemo(() => api.orders, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const getLatestOrder = React.useCallback(async () => {
    const id = window.location.pathname.split("/").pop().trim();
    if (id && !isNaN(id)) {
      try {
        const response = await sendRequest(dataResource.getOne(id));
        setOrder(response);
      } catch (err) {
        toast.error("An error has occurred");
        history.push({
          pathname: `/admin/${path}`,
        });
      }
    } else {
      toast.error("An error has occurred");
      history.push({
        pathname: `/admin/${path}`,
      });
    }
  }, [dataResource]);

  React.useEffect(() => {
    getLatestOrder();
  }, [getLatestOrder]);

  const updateOrderState = React.useCallback(
    (updatedOrder) => {
      setOrder({
        ...order,
        ...updatedOrder,
      });
    },
    [order]
  );

  return {
    isGetOrderLoading,
    order,
    currentTab,
    isOpen,
    setOrder,
    setCurrentTab,
    getLatestOrder,
    onClose,
    updateOrderState,
  };
};
