import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormHelperText from "@material-ui/core/FormHelperText";

// @material-ui/icons
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LockIcon from "@material-ui/icons/Lock";

import { useHistory } from "react-router";
import { useForm } from "react-hook-form";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import validators from "shared/util/validators";
import api from "services/api";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import { whiteColor } from "assets/jss/material-dashboard-pro-react";

const useStyles = makeStyles(styles);

export default function LoginPage() {
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  React.useEffect(() => {
    let id = setTimeout(function () {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });
  const classes = useStyles();

  const history = useHistory();

  const { register, handleSubmit, errors, getValues } = useForm();

  const [sendRequest, isLoading, error, _, clearError] = useHttpClient();

  const auth = React.useContext(AuthContext);

  const handleAuthentication = async () => {
    clearError();
    try {
      let authData = {
        id: getValues("id"),
        password: getValues("password"),
      };
      const response = await sendRequest(api.login(authData));
      auth.login();
    } catch (err) { }
  };

  return (
    <div className={classes.container}>
      <h1 style={{ color: whiteColor, fontWeight: "bold" }}>Welcome to Ramtrade Dashboard</h1>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
          <form onSubmit={handleSubmit(handleAuthentication)}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="black"
              >
                <h4 className={classes.cardTitle}>Admin</h4>
              </CardHeader>

              {error && !error.param && (
                <FormHelperText className={classes.helperText} filled>
                  {error.message}
                </FormHelperText>
              )}

              <CardBody>
                <CustomInput
                  labelText="Email or Username"
                  id="id"
                  error={!!errors.id?.message}
                  helperText={errors.id?.message}
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    ),
                    inputRef: register(validators.id),
                    name: "id",
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  error={!!errors.password?.message}
                  helperText={errors.password?.message}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <LockIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off",
                    inputRef: register({
                      ...validators.required,
                      ...validators.password,
                    }),
                    name: "password",
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button
                  type="submit"
                  color="black"
                  // simple
                  size="lg"
                  block
                  loading={isLoading}
                  loadingColor="white"
                >
                  Log in
                </Button>
              </CardFooter>
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}
