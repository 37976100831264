import DataList from "components/DataList/DataList";
import FormContainer from "components/Forms/FormContainer/FormContainer";
import enums from "enums/index";
import api from "services/api";
import React from "react";
import { Space, Tag } from "antd";
import { displayItemName } from "utils/index";
import manufacturersForm from "views/ItemsManagement/manufacturersForm";

// appear in breadcrumbs and page headers
const listPageName = "Manufacturers";
const createPageName = "Create Manufacturer";
const editPageName = "Edit Manufacturer";

// appears on alerts; example: Entity created successfully
const entityName = "Manufacturer";

const layout = "/admin";

const path = "manufacturers";

const formStructure = manufacturersForm;

const dataResource = api.manufacturers;

const resource = enums.Resource.ITEMS_MANAGEMENT;

const columns = [
  {
    title: "Name",
    dataIndex: "name",
    canBeHighlighted: true,
  },
  {
    title: "Items",
    dataIndex: "items",
    render: (data) => {
      if (data?.length > 0) {
        return (
          <Space size={[0, 8]} wrap>
            {data.map((item) => (
              <Tag key={item.id}>{displayItemName(item)}</Tag>
            ))}
          </Space>
        );
      } else {
        return "-";
      }
    },
  },
];

export default {
  path: `/${path}`,
  name: listPageName,
  sidebarName: listPageName,
  icon: "factory",
  render: () => (
    <DataList
      entityName={entityName}
      path={path}
      columns={columns}
      actions={{ edit: true, delete: true }}
      dataResource={dataResource}
      resource={resource}
      listPageName={listPageName}
      formStructure={formStructure}
      formType={enums.FormContainerType.MODAL}
    />
  ),
  action: "read",
  layout: layout,
  // randomKey: true,
  children: true,
  superAdmin: true,
  resource,
  views: [
    {
      path: `/${path}/create`,
      name: createPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          create
        />
      ),
      layout: layout,
      randomKey: true,
      action: "create",
      superAdmin: true,
      resource,
    },
    {
      path: `/${path}/:id/edit`,
      name: editPageName,
      render: () => (
        <FormContainer
          entityName={entityName}
          dataResource={dataResource}
          formStructure={formStructure}
          path={path}
          edit
        />
      ),
      layout: layout,
      randomKey: true,
      action: "update",
      superAdmin: true,
      resource,
    },
  ],
};
